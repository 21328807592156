import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import Img from "../../assets/media/imgIcon.svg";
const Post = (props) => {
  const handleClose = () => {
    props.setShow(false);
  };
  useEffect(() => {
    userDetails();
    if (userToken === "" || userToken === null) {
    }
  }, []);
  const [details, setDetails] = useState([]);
  const userToken = localStorage.getItem("userToken");

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoc, setImageLoc] = useState(null);
  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImageLoc(file);
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    } else {
      setSelectedImage("");
    }
  };
  const [formData, setFormData] = useState({
    postQuestion: "",
    postAnswer: "",
    postImage: null,
    postPrivacy: "public",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append("postQuestion", formData.postQuestion);
    data.append("postAnswer", formData.postAnswer);
    data.append("postImage", imageLoc);
    data.append("postPrivacy", formData.postPrivacy);

    const config = {
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .post("https://api.multigrad.in/auth/post", data, config)
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Post Created Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Email Id not exist",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Email Id not exist",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const userDetails = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/user",
      headers: {
        authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDetails(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="custom-login">
      <Modal
        show={props.show}
        onHide={handleClose}
        className="create-post-form"
      >
        <Modal.Header className="border-0"></Modal.Header>
        <div>
          <button onClick={handleClose} id="closeBtn">
            <AiOutlineClose id="modalIcon" />
          </button>
          <div className="userDetails">
            <img src={details.userProfile} />
            <p>{details.fullName}</p>
            <select
              name="postPrivacy"
              value={formData.postPrivacy}
              onChange={handleChange}
            >
               <option value="">Select</option>
              <option value="public">Public</option>
              <option value="circle">My College</option>
            </select>
          </div>

          <Modal.Body className="modalBody">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="postQuestion"
                id="postQuestion"
                placeholder="What is buffering in your mind?*"
                value={formData.postQuestion}
                onChange={handleChange}
              />

              <input
                type="text"
                name="postAnswer"
                placeholder={"Your answer..."}
                id="postAnswer"
                value={formData.postAnswer}
                onChange={handleChange}
              />
              <div className="modalBottom">
                {selectedImage ? (
                  <img src={selectedImage} alt="Selected Image" />
                ) : (
                  <img src={Img} alt="Selected Image" id="defaultImg" />
                )}
                <input
                  type="file"
                  name="postImage"
                  id="postImage"
                  onChange={handleChangeImage}
                />

                <button type="submit" id="publishBtn">
                  Post
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Post;
