import React from 'react';
import Hackathons from '../../../screens/hackathons/allListings';

const DashHackathons = () => {
  return (
    <div>
    <div className='graphs'>
      <Hackathons />
    </div>

    </div>
  );
};

export default DashHackathons;
