import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const FacultyForm = () => {
  const [formData, setFormData] = useState({});
  const userToken = localStorage.getItem("userToken");
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_AUTH}/user`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setFormData(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleArrayChange = (e, field) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: { ...prevData[field], [name]: value },
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({ ...formData });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_AUTH}/user`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status === true) {
          await Swal.fire({
            icon: "success",
            title: "Faculty form submitted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <Container className="p-5">
      <h1 className="my-4 pt-5">Faculty Form</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="fullName">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData?.fullName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                required
                disabled
              />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData?.phone}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="gender">
              <Form.Label>Gender*</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formData?.gender}
                onChange={handleChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData?.dob}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="university">
              <Form.Label>Select University</Form.Label>
              <Form.Control
                as="select"
                name="university"
                value={formData?.university}
                onChange={handleChange}
              >
                <option value="">Select University</option>
                <option value="Rajju-Bhaiyya-University-Allahabad">
                  Rajju Bhaiyya University, Allahabad
                </option>
                <option value="Chhatrapati-Shivaji-University-Kanpur">
                  Chhatrapati Shivaji University, Kanpur
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="course">
              <Form.Label>Course</Form.Label>
              <Form.Control
                as="select"
                name="course"
                value={formData?.course}
                onChange={handleChange}
              >
                <option value="">Select Course</option>
                <option value="Medical-Lab-Technology">
                  Medical Lab Technology
                </option>
                <option value="BMLT">BMLT</option>
                <option value="Bsc-MLT">Bsc.MLT</option>
                <option value="B-Voc-MLT">B.Voc MLT</option>
                <option value="DMLT">DMLT</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="session">
              <Form.Label>Session</Form.Label>
              <Form.Control
                as="select"
                name="session"
                value={formData?.session}
                onChange={handleChange}
              >
                <option value="">Select a session</option>
                <option value="2024-25">2024-25</option>
                <option value="2025-26">2025-26</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                value={formData?.address}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData?.state}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData?.city}
                onChange={handleChange}
              />
            </Form.Group>

          </Col>

          <Col md={6}>

          <Form.Group controlId="pincode">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                name="pincode"
                value={formData?.pincode}
                onChange={handleChange}
              />
            </Form.Group>

            
            <Form.Group controlId="certificates">
              <Form.Label>Certificates</Form.Label>
              <Form.Control
                as="textarea"
                name="certificates"
                value={formData?.certificates}
                onChange={(e) => handleArrayChange(e, 'certificates')}
              />
            </Form.Group>

            <Form.Group controlId="projects">
              <Form.Label>Projects</Form.Label>
              <Form.Control
                as="textarea"
                name="projects"
                value={formData?.projects}
                onChange={(e) => handleArrayChange(e, 'projects')}
              />
            </Form.Group>

            <Form.Group controlId="experience">
              <Form.Label>Experience</Form.Label>
              <Form.Control
                as="textarea"
                name="experience"
                value={formData?.experience}
                onChange={(e) => handleArrayChange(e, 'experience')}
              />
            </Form.Group>

            <Form.Group controlId="department">
              <Form.Label>Department</Form.Label>
              <Form.Control
                type="text"
                name="department"
                value={formData?.department}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="joiningYear">
              <Form.Label>Joining Year</Form.Label>
              <Form.Control
                type="text"
                name="joiningYear"
                value={formData?.joiningYear}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="designation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                name="designation"
                value={formData?.designation}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="subjectsCanTeach">
              <Form.Label>Subjects Can Teach</Form.Label>
              <Form.Control
                as="textarea"
                name="subjectsCanTeach"
                value={formData?.subjectsCanTeach}
                onChange={(e) => handleArrayChange(e, 'subjectsCanTeach')}
              />
            </Form.Group>

            <Form.Group controlId="nationality">
              <Form.Label>Nationality</Form.Label>
              <Form.Control
                as="select"
                name="nationality"
                value={formData?.nationality}
                onChange={handleChange}
              >
                <option value="">Select Nationality</option>
                <option value="Indian">Indian</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={formData?.category}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                <option value="General">General</option>
                <option value="OBC">OBC</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default FacultyForm;
