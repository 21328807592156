import React, { useState } from "react";
import { Table, NavDropdown, Pagination, Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import { ShimmerTable } from "react-shimmer-effects";
import SearchForm from "../faculty/SearchForm";

const ManageStudents = () => {
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of students per page
  const [loading, setLoading] = useState(false); // New state for loading
  const userToken = localStorage.getItem("userToken");

  const handleSearch = (filter) => {
    setLoading(true); // Set loading to true when starting to fetch data
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/allusers-alumni?semester=${filter?.semester}&university=${filter?.university}&session=${filter?.session}&course=${filter?.course}&showAdmForm=true&userType=student`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFilteredStudents(response.data.data);
        setCurrentPage(1); // Reset to first page after search
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false in case of an error
      });
  };



  const toggleShowAdmForm = (studentId) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/showadmform/${studentId}`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          icon: "success",
          title: "Admission Rejected Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const toggleCompleted = (studentId) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/alumni/${studentId}`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          icon: "success",
          title: "Alumni Rejected Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleView = (id) => {
    window.location.href = `/admin/view/${id}`;
  };

  const handleFees = (id) => {
    window.location.href = `/admin/fees/${id}`;
  };

  const handleAdmitCard = (id) => {
    window.location.href = `/student/idcard/${id}`;
  };

  const limitToTwoWords = (text) => {
    const words = text.split(" ");
    return words.length <= 2 ? text : `${words.slice(0, 2).join(" ")}...`;
  };

  // Get current students
  const indexOfLastStudent = currentPage * itemsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - itemsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredStudents.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container" style={{ marginTop: "110px" }}>
      <h1>Manage Alumni</h1>
      <SearchForm onSearch={handleSearch} />
      
      <div>
        {loading ? (
          <ShimmerTable
            columns={[
              { width: 50 },
              { width: 100 },
              { width: 150 },
              { width: 80 },
              { width: 150 },
              { width: 80 },
              { width: 100 },
              { width: 80 },
              { width: 80 },
              { width: 80 },
              { width: 100 },
              { width: 150 },
            ]}
            rows={10} // Number of rows to show in shimmer
          />
        ) : filteredStudents.length === 0 ? (
          <p>No Alumni found</p>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sn</th>
                  <th>ERN No.</th>
                  <th>Name</th>
                  <th>Sem.</th>
                  <th>Course</th>
                  <th>Session</th>
                  <th>Att. (%)</th>
                  <th>S.Fees</th>
                  <th>D.Fees</th>
                  <th>T.Fees</th>
                  <th>Last S.F</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents.map((student, key) => (
                  <tr key={student._id}>
                    <td>{indexOfFirstStudent + key + 1}</td>
                    <td>{student.ernNo}</td>
                    <td>{limitToTwoWords(student.fullName)}</td>
                    <td>{student.semester}</td>
                    <td>{limitToTwoWords(student.course)}</td>
                    <td>{student.session}</td>
                    <td>{student.attendance || 0}%</td>
                    <td>{student.paidFees || 0}</td>
                    <td id="red">{student.pendingFees || 0}</td>
                    <td>{student.pendingFees + student.paidFees || 0}</td>
                    <td>{moment(student?.lastSubmittedFee?.dueDate).format("DD/MM/YYYY") || "na"}</td>
                    <td className="manage-s">
                      <NavDropdown title="Manage">
                        <NavDropdown.Item>
                          <button onClick={() => handleView(student._id)}>View/Edit</button>
                        </NavDropdown.Item>
                        
                        <NavDropdown.Item>
                          <button onClick={() => handleAdmitCard(student._id)}>Admit Card</button>
                        </NavDropdown.Item>
                        
                        <NavDropdown.Item>
                          <button
                            className={student.showAdmForm ? "btn-success" : "btn-danger"}
                            onClick={() => toggleCompleted(student._id)}
                          >
                            {student.showAdmForm ? "Passed in" : "Reject Admission"}
                          </button>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination>
              {pageNumbers.map((number) => (
                <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                  {number}
                </Pagination.Item>
              ))}
            </Pagination>
          </>
        )}
      </div>
    </div>
  );
};

export default ManageStudents;
