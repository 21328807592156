import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { NavLink, useLocation } from "react-router-dom";
import { VscGraph } from "react-icons/vsc";
import { MdOutlineDataSaverOn } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { PiChalkboardTeacherBold } from "react-icons/pi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { TbMessage2 } from "react-icons/tb";
import MenuItems from "../../../dashboard/MenuItems";
import LogoBar from "../../../dashboard/LogoBar";
import TopBarComp from "../../../dashboard/TopBarComp";
import { useParams } from "react-router-dom";

const AdmissionForm = () => {
  const [formData, setFormData] = useState({});
  const userToken = localStorage.getItem("userToken");
  const [admissionsOpen, setAdmissionsOpen] = useState(false);
  const [studentsOpen, setStudentsOpen] = useState(false);
  const [facultyOpen, setFacultyOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const location = useLocation();
  const [dropData, setDropData] = useState({});
  const { id } = useParams();

  const loadDropData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/secure/dropdown?campusCode=${process.env.REACT_APP_CAMPUS_CODE}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setDropData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Effect to open dropdowns based on current path
  useEffect(() => {
    loadDropData();
    const pathname = location.pathname;
    if (pathname.includes("/admissions")) {
      setAdmissionsOpen(true);
    } else if (pathname.includes("/students")) {
      setStudentsOpen(true);
    } else if (pathname.includes("/faculty")) {
      setFacultyOpen(true);
    } else if (pathname.includes("/publish")) {
      setPublishOpen(true);
    }
  }, [location.pathname]);

  const toggleAdmissionsDropdown = () => {
    setAdmissionsOpen(!admissionsOpen);
  };

  const toggleStudentsDropdown = () => {
    setStudentsOpen(!studentsOpen);
  };

  const toggleFacultyDropdown = () => {
    setFacultyOpen(!facultyOpen);
  };

  const togglePublishDropdown = () => {
    setPublishOpen(!publishOpen);
  };

  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/userdetails?userId=${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFormData(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({ ...formData, admFormFilled: true });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/admformadmin`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status === true) {
          await Swal.fire({
            icon: "success",
            title: "Admission form submitted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <div className="dash-menu">
      <div className="sidemenu">
        <div className="rightmenu-logo">
          <LogoBar />
        </div>

        <MenuItems />
      </div>
      <div className="rightdash">
        <TopBarComp />

        <Form onSubmit={handleSubmit} className="graphs">
          <Row className="container">
            <h1 className="my-4">Manage Student Details</h1>
            <Col md={6}>
              <Form.Group controlId="registrationNumber">
                <Form.Label>Enrollment Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="ernNo"
                  value={formData.ernNo}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="fullName">
                <Form.Label>Full Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="fullName">
                <Form.Label>Father's Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="fatherName"
                  value={formData.fatherName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="fullName">
                <Form.Label>Mother's Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="motherName"
                  value={formData.motherName}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="fullName">
                <Form.Label>Gender*</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="dob">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="gender">
                <Form.Label>Select University</Form.Label>
                <Form.Control
                  as="select"
                  name="university"
                  value={formData.university}
                  onChange={handleChange}
                >
                  <option value="">Select University</option>
                  {dropData?.universities?.map((s) => (
                    <option value={s.name}>{s.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="semester">
                <Form.Label>Semester</Form.Label>
                <Form.Control
                  as="select"
                  name="semester"
                  value={formData.semester}
                  onChange={handleChange}
                >
                  <option value="">Select your semester</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="course">
                <Form.Label>Course</Form.Label>
                <Form.Control
                  as="select"
                  name="course"
                  value={formData.course}
                  onChange={handleChange}
                >
                  <option value="">Select Course</option>
                  {dropData?.courses?.map((s) => (
                    <option value={s.name}>{s.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="session">
                <Form.Label>Session</Form.Label>
                <Form.Control
                  as="select"
                  name="session"
                  value={formData.session}
                  onChange={handleChange}
                >
                  <option value="">Select a session</option>
                  {dropData?.sessions?.map((s) => (
                    <option value={s.session}>{s.session}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="pincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="nationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Control
                  as="select"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                >
                  <option value="">Select Nationalityy</option>
                  <option value="Indian">Indian</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  <option value="General">General</option>
                  <option value="OBC">OBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AdmissionForm;
