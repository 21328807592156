import React from 'react';
import '../assets/css/NewDashboard.css';
import TopMenu from './TopMenu';
import MenuItems from './MenuItems';
import LogoBar from './LogoBar';

const SideMenu = () => {


  return (
    <div className='dash-menu'>
      <div className='sidemenu'>
        <div className='rightmenu-logo'>
         <LogoBar />
        </div>

      <MenuItems />


      </div>
      <TopMenu />
    </div>
  );
};

export default SideMenu;
