import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";

const Input = ({ userToken, senderId, receiverId,community }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let data;
if(community){
      data = JSON.stringify({
      senderId: senderId,
      message: message,
      groupId: receiverId
    });
  }
  else{
    data = JSON.stringify({
      senderId: senderId,
      message: message,
      receiverId: receiverId,
    });
  }

    
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/chat",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-container input-page">
        <input
          required
          value={message}
          onChange={handleInputChange}
          type="text"
          placeholder="Type here.."
        />
        <button type="submit" className="btn btn-dark chat-input-btn">
          Send
        </button>
      </div>
    </form>
  );
};

export default Input;
