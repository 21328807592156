import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const AdmissionFormAdminCreate = () => {
  const [formData, setFormData] = useState({
    campusCode: process.env.REACT_APP_CAMPUS_CODE || "",
  });
  const [dropData, setDropData] = useState({});
  const [ernNo, setErn] = useState();
  const [course, setCourse] = useState({});
  const userToken = localStorage.getItem("userToken");
  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/userdetails?userId=${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setFormData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadERN = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/enrollment`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setErn(response.data.ernNo);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadDropData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/secure/dropdown?campusCode=${process.env.REACT_APP_CAMPUS_CODE}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setDropData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
    loadDropData();
    loadERN();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({ ...formData,course:course?.name,pendingFees:course?.fees||0, admFormFilled: true });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_AUTH_SECURE}/secure/registration`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status === true) {
          await Swal.fire({
            icon: "success",
            title: "Admission form created Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <Container className="p-5">
      <h1 className="my-4 pt-5">New Admission Form</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="registrationNumber">
              <Form.Label>Enrollment Number*</Form.Label>
              <Form.Control
                type="text"
                name="ernNo"
                value={ernNo}
                onChange={handleChange}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="fullName">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData?.fullName}
                onChange={handleChange}
               
              />
            </Form.Group>
            <Form.Group controlId="fullName">
              <Form.Label>Father's Name*</Form.Label>
              <Form.Control
                type="text"
                name="fatherName"
                value={formData?.fatherName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="fullName">
              <Form.Label>Mother's Name*</Form.Label>
              <Form.Control
                type="text"
                name="motherName"
                value={formData?.motherName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="fullName">
              <Form.Label>Gender*</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formData?.gender}
                onChange={handleChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData?.dob}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="gender">
              <Form.Label>Select University</Form.Label>
              <Form.Control
                as="select"
                name="university"
                value={formData.university}
                onChange={handleChange}
              >
                <option value="">Select University</option>
                {dropData?.universities?.map((s) => (
                  <option value={s.name}>{s.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="semester">
              <Form.Label>Semester/Class</Form.Label>
              <Form.Control
                as="select"
                name="semester"
                value={formData?.semester}
                onChange={handleChange}
              >
                   <option value="">Select your semester/class</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="course">
              <Form.Label>Course</Form.Label>
              <Form.Control
                as="select"
                name="course"
                onChange={(e) => setCourse(JSON.parse(e.target.value))}
              >
                <option value="">Select Course</option>
                {dropData?.courses?.map((s) => (
                  <option value={JSON.stringify(s)}>
                    {s.name} - (Fees - ₹{s.fees||0})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="session">
              <Form.Label>Session</Form.Label>
              <Form.Control
                as="select"
                name="session"
                value={formData.session}
                onChange={handleChange}
              >
                <option value="">Select a session</option>
                {dropData?.sessions?.map((s) => (
                  <option value={s.session}>{s.session}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                value={formData?.address}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData?.state}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData?.city}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="pincode">
  <Form.Label>Pincode</Form.Label>
  <Form.Control
    type="number"
    name="pincode"
    value={formData?.pincode}
    onChange={handleChange}
    maxLength={6}
    pattern="\d{6}"  
  />
</Form.Group>


            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={formData?.password}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="phone">
  <Form.Label>Phone</Form.Label>
  <Form.Control
    type="number"
    name="phone"
    value={formData?.phone}
    onChange={handleChange}
    maxLength={10}
    pattern="\d{10}"
  />
</Form.Group>



            <Form.Group controlId="nationality">
              <Form.Label>Nationality</Form.Label>
              <Form.Control
                as="select"
                name="nationality"
                value={formData?.nationality}
                onChange={handleChange}
              >
                <option value="">Select Nationalityy</option>
                <option value="Indian">Indian</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={formData?.category}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                <option value="General">General</option>
                <option value="OBC">OBC</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default AdmissionFormAdminCreate;
