import React from 'react';
import CourseList from '../../../screens/cms/admin/CourseList';

const CoursesDetails = () => {
  return (
    <div>
    <div className='graphs'>
      <CourseList />
    </div>

    </div>
  );
};

export default CoursesDetails;
