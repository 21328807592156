import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
const Messages = ({ receiverId, senderId, userToken }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/auth/chat/${senderId}/${receiverId}`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
        setMessages(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchChatData();
  }, [messages]);

  return (
    <Container>
    <div className="msg-container-page container">
      {messages
        .sort((a, b) => b - a)
        .map((m) => (
          <div className={senderId === m.senderId ? "msg" : "msg-owner"}>
            <p className="msg-text">{m.message}</p>
          </div>
        ))}
    </div>
    </Container>
  );
};

export default Messages;
