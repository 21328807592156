import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Form, Button, Container,Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { NavLink, useLocation } from 'react-router-dom';
import MenuItems from "../../../dashboard/MenuItems";
import LogoBar from "../../../dashboard/LogoBar";
import TopBarComp from "../../../dashboard/TopBarComp";

const FeeForm = () => {
  const [subject, setSubject] = useState({
    name:"",
    amount: "",
    dueDate: "",
    semester: "",
    status:"paid",
    nextDueDate:''
    });
    const [fees, setFees] = useState([]);
    const userToken = localStorage.getItem("userToken");
    const { id } = useParams();
    


  const loadFeesData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/fees?userId=${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFees(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/userdetails?userId=${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      }
    };

    axios
      .request(config)
      .then(async (response) => {
        setSubject({...subject,name:response?.data?.data?.fullName});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
    loadFeesData();
  }, [])
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubject((prevSubject) => ({
      ...prevSubject,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      axios
        .post(`${process.env.REACT_APP_API}/fees`, {...subject,userId:id}, {
          headers: {
            authorization: userToken,
          },
        })
        .then(async () => {
          await Swal.fire({
            icon: "success",
            title: "Fees added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload()
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });

          console.error("There was an error making the POST request:", error);
        });
  };

  return (
    <div className='dash-menu'>
    <div className='sidemenu'>
      <div className='rightmenu-logo'>
        <LogoBar />
      </div>
 
  <MenuItems />
      

    </div>
    <div className="rightdash">
      
       <TopBarComp />
   
        
        
      <div className="graphs">

<div  className="container">
<h1>Manage {subject?.name}'s Fees</h1>
        <Form onSubmit={handleSubmit} className="manage-fees">
       

        <Form.Group controlId="amount">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={subject?.name}
            />
          </Form.Group>


          <Form.Group controlId="amount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={subject?.amount}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="semester">
            <Form.Label>Semester</Form.Label>
            <Form.Control
              type="text"
              name="semester"
              value={subject.semester}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="dueDate">
            <Form.Label>Submitted Date</Form.Label>
            <Form.Control
              type="date"
              name="dueDate"
              value={subject.dueDate}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="nextDueDate">
            <Form.Label>Next Due Date</Form.Label>
            <Form.Control
              type="date"
              name="nextDueDate"
              value={subject.nextDueDate}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {"Create"}
          </Button>
        
  
        </Form>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Amount</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Download Receipt</th>
          </tr>
        </thead>
        <tbody>
          {fees?.map((fee, index) => (
            <tr key={fee._id}>
              <td>{index + 1}</td>
              <td>{fee.amount}</td>
              <td>{new Date(fee.dueDate).toLocaleDateString()}</td>
              <td>{fee.status}</td>
              <td><a href={`/student/feereceipt/${fee._id}`} >Download</a></td>
            </tr>
          ))}
        </tbody>
      </Table>
        </div>
        </div>
    </div></div>
  );
};

export default FeeForm;
