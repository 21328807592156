import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../cms.css'; 
import { useParams } from 'react-router-dom';


const FeeReceipt = () => {
    const {id} = useParams();
  const [fee, setFee] = useState(null);
  const [user, setUser] = useState(null);

  const userToken = localStorage.getItem("userToken");

  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:  `${process.env.REACT_APP_API}
/fees/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFee(response.data.fee);
        setUser(response.data.user);
      
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!fee || !user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="receipt-container">
      <h1 className="receipt-title">University Fee Receipt</h1>
      <div className="receipt-details">
        <div className="receipt-header">
          <h2>{user?.university}</h2>
        </div>
        <div className="receipt-body">
          <p><strong>Student Name:</strong> {user?.fullName}</p>
          <p><strong>Father's Name:</strong> {user?.fatherName}</p>
          <p><strong>Enrollment No:</strong> {user?.ernNo}</p>
          <p><strong>Course:</strong> {user?.course}</p>
          <p><strong>Session:</strong> {user?.session}</p>
          <p><strong>Email:</strong> {user?.email}</p>
          <p><strong>Semester:</strong> {fee?.semester}</p>
          <p><strong>Amount:</strong> Rs. {fee?.amount.toFixed(2)}</p>
          <p><strong>Due Date:</strong> {new Date(fee?.dueDate).toLocaleDateString()}</p>
          <p><strong>Status:</strong> {fee?.status}</p>
        </div>
        <div className="receipt-footer">
          <p>Thank you for your payment.</p>
          <p>If you have any questions, please contact the university's finance office.</p>
        </div>
      </div>
    </div>
  );
};

export default FeeReceipt;
