import "./assets/css/App.css";
import "./assets/css/hackathon.css";
import "./assets/css/mobile.css";
import "./assets/css/Dash2.css";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import Header2 from "./common/Header";
import LoginAccount from "./screens/cms/LoginAccount";
import NewAccount from "./screens/cms/NewAccount";
import AttendanceFaculty from "./screens/cms/faculty/Attendance";
import MarksFaculty from "./screens/cms/faculty/Marks";
import AdmitCard from "./screens/cms/student/AdmitCard";
import ExamForm from "./screens/cms/student/ExamForm";
import AttendanceStudent from "./screens/cms/student/Attendance";
import MarksStudent from "./screens/cms/student/Marks";
import Subjects from "./screens/cms/student/Subjects";
import Fees from "./screens/cms/student/Fees";
import FeeForm from "./screens/cms/admin/FeeForm";
import BulkRegistration from "./screens/cms/admin/BulkRegistration";
import AdmissionForm from "./screens/cms/student/AdmissionForm";
import FeeReceipt from "./screens/cms/student/FeeReceipt";
import FacultyFormAdmin from "./screens/cms/admin/FacultyFormAdmin";
import FacultyForm from "./screens/cms/faculty/FacultyFormAdmin";
import AdminDashboard from "./dashboard/Index";
import IdCard from "./screens/cms/student/IdCard";
import SubjectForm from "./screens/cms/admin/SubjectForm";
import CourseForm from "./screens/cms/admin/CourseForm";
import UniversityForm from "./screens/cms/admin/UniversityForm";
import SessionForm from "./screens/cms/admin/SessionForm";
import AdmitCards from "./screens/cms/student/Admit Cards";
import BulkAdmission from "./screens/cms/admin/BulkAdmission";
import NotFound from "./dashboard/NotFound";

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.slice(1).charAt(0).toUpperCase()) {
      document.title =
        location.pathname.slice(1).charAt(0).toUpperCase() +
        location.pathname.slice(2) +
        " | Multigrad";
    } else {
      document.title = "Multigrad | Connect with your circle & interest!";
    }
  }, [location]);

  const userToken = localStorage.getItem("userToken");

  const renderWithAuth = (element) => {
    return userToken ? element : window.location.href = '/';
  }

  return (
    <>
      <Routes>
      
        <Route path="/" element={<><LoginAccount /></>} />
        <Route path="/register" element={<><Header2 /><NewAccount /></>} />

       
        <Route path="/dashboard" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/admissions/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/admissions/bulk" element={renderWithAuth(<BulkAdmission />)} />
        <Route path="/admissions/requests" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/students/manage" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/students/manage/:id" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/faculty/manage" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/admin/fees/:id" element={renderWithAuth(<FeeForm />)} />
        <Route path="/admin/viewfaculty/:id" element={renderWithAuth(<FacultyFormAdmin />)} />
        <Route path="/admin/view/:id" element={renderWithAuth(<AdmissionForm />)} />
        <Route path="/faculty/create" element={renderWithAuth(<BulkRegistration />)} />
        <Route path="/messaging" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/settings" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/hackathons" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/hackathon/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/jobs" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/job/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/course/all" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/course/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/session/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/session/all" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/subject/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/subject/all" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/university/all" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/university/new" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/students/search" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/students/alumni" element={renderWithAuth(<><AdminDashboard /></>)} />
        <Route path="/feed" element={renderWithAuth(<><AdminDashboard /></>)} />
        
        {/* Authenticated Routes with Forms */}
        <Route path="/course/:id" element={renderWithAuth(<CourseForm />)} />
        <Route path="/subject/:id" element={renderWithAuth(<SubjectForm />)} />
        <Route path="/university/:id" element={renderWithAuth(<UniversityForm />)} />
        <Route path="/session/:id" element={renderWithAuth(<SessionForm />)} />

        {/* Student Routes */}
        <Route path="/student/admit-card/all" element={renderWithAuth(<><Header2 /><AdmitCards /></>)} />
        <Route path="/admit-card/:id" element={renderWithAuth(<AdmitCard />)} />
        <Route path="/student/exam-form" element={renderWithAuth(<><Header2 /><ExamForm /></>)} />
        <Route path="/student/attendance" element={renderWithAuth(<><Header2 /><AttendanceStudent /></>)} />
        <Route path="/student/marks" element={renderWithAuth(<><Header2 /><MarksStudent /></>)} />
        <Route path="/student/subjects" element={renderWithAuth(<><Header2 /><Subjects /></>)} />
     
        <Route path="/student/fees" element={renderWithAuth(<><Header2 /><Fees /></>)} />
        <Route path="/student/adm-form" element={renderWithAuth(<><Header2 /><AdmissionForm /></>)} />
        <Route path="/student/feereceipt/:id" element={renderWithAuth(<FeeReceipt />)} />
        <Route path="/student/idcard/:id" element={renderWithAuth(<IdCard />)} />

        {/* Faculty Routes */}
        <Route path="/faculty/attendance" element={renderWithAuth(<><Header2 /><AttendanceFaculty /></>)} />
        <Route path="/faculty/marks" element={renderWithAuth(<><Header2 /><MarksFaculty /></>)} />
        <Route path="/faculty/profile" element={renderWithAuth(<><Header2 /><FacultyForm /></>)} />

        {/* Not Found Route */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
