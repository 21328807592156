import React from 'react';
import ManageStudentsRequests from '../../../screens/hackathons/publishHackathon';

const ManageHackathons = () => {
  return (
    <div>
    <div className='graphs'>
      <ManageStudentsRequests />
    </div>

    </div>
  );
};

export default ManageHackathons;
