import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Hackathons = () => {
  const navigate = useNavigate();

  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    hackathonName: "",
    hackathonTheme: "",
    hackathonMode: "",
    hackathonBanner: "",
    hackathonOverview: "",
    hackathonStartTime: "",
    hackathonEndTime: "",
    hackathonRegistrationStartTime: "",
    hackathonRegistrationEndTime: "",
    hackathonResult: "",
    whoCanParticipate: "",
    universityName: "",
    hackathonOrganisersEmail: "",
    hackathonRepresentingFrom: "",
    hackathonRules: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    let cI = "";

    if (formData.hackathonBanner == "") {
      cI = "https://api.multigrad.in/open/media/?file=1689670312760.png";
    } else {
      cI = formData.hackathonBanner;
    }

    const data = JSON.stringify({
      hackathonName: formData.hackathonName,
      hackathonTheme: formData.hackathonTheme,
      hackathonMode: formData.hackathonMode,
      hackathonBanner: cI,
      hackathonOverview: formData.hackathonOverview,
      hackathonStartTime: formData.hackathonStartTime,
      hackathonEndTime: formData.hackathonEndTime,
      hackathonRegistrationStartTime: formData.hackathonRegistrationStartTime,
      hackathonRegistrationEndTime: formData.hackathonRegistrationEndTime,
      hackathonResult: formData.hackathonResult,
      whoCanParticipate: formData.whoCanParticipate,
      universityName: formData.universityName,
      hackathonOrganisersEmail: formData.hackathonOrganisersEmail,
      hackathonRepresentingFrom: formData.hackathonRepresentingFrom,
      hackathonRules: formData.hackathonRules,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/hackathon`,
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        navigate("/hackathons");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [currentStep, setCurrentStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Function to move to the previous step
  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // Render different form sections based on the current step
  const renderFormSections = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="mg-job-form">
            <div className="mg-job-form">
              <div className="job-input-sections">
                <label>Hackathon Name*</label>
                <input
                  type="text"
                  placeholder="Eg: Ai Builder"
                  value={formData.hackathonName}
                  onChange={handleChange}
                  name="hackathonName"
                  required
                />
              </div>
            </div>
            <div className="job-input-sections">
              <label>Hackathon Theme*</label>
              <input
                type="text"
                placeholder="Eg: AI & Ml"
                value={formData.hackathonTheme}
                onChange={handleChange}
                name="hackathonTheme"
                required
              />
            </div>
            <div className="job-input-sections">
              <label>Hackathon Overview*</label>
              <textarea
                type="text"
                placeholder="Eg: Brief descripton "
                value={formData.hackathonOverview}
                onChange={handleChange}
                name="hackathonOverview"
                required
              />
            </div>

            <div className="job-input-sections">
              <label>Hackathon Banner </label>
              <input
                type="text"
                placeholder="Eg: https://example.com/banner-512.png"
                value={formData.hackathonBanner}
                onChange={handleChange}
                name="hackathonBanner"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="mg-job-form">
            <div className="mg-job-form"></div>
            <div className="job-input-sections">
              <label>Registration Start Time*</label>
              <input
                type="date"
                placeholder="Eg: EdTech"
                value={formData.hackathonRegistrationStartTime}
                onChange={handleChange}
                name="hackathonRegistrationStartTime"
                required
              />
            </div>

            <div className="job-input-sections">
              <label>Registration End Time*</label>
              <input
                type="date"
                placeholder="Eg: EdTech"
                value={formData.hackathonRegistrationEndTime}
                onChange={handleChange}
                name="hackathonRegistrationEndTime"
                required
              />
            </div>
            <div className="mg-job-form"></div>
            <div className="job-input-sections">
              <label>Hackathon Start Time*</label>
              <input
                type="date"
                placeholder="Eg: EdTech"
                value={formData.hackathonStartTime}
                onChange={handleChange}
                name="hackathonStartTime"
                required
              />
            </div>

            <div className="job-input-sections">
              <label>Hackathon End Time*</label>
              <input
                type="date"
                placeholder="Eg: EdTech"
                value={formData.hackathonEndTime}
                onChange={handleChange}
                name="hackathonEndTime"
                required
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="mg-job-form">
            <div className="job-input-sections">
              <label>Hackathon Mode*</label>
              <select
                value={formData.hackathonMode}
                onChange={handleChange}
                name="hackathonMode"
                required
              >
                <option>Online</option>
                <option>Offline</option>
              </select>
            </div>
            <div className="job-input-sections">
              <label>Hackathon Rules*</label>
              <textarea
                type="text"
                placeholder="Hackathon Rules"
                value={formData.hackathonRules}
                onChange={handleChange}
                name="hackathonRules"
                required
              />
            </div>
            <div className="job-input-sections">
              <label>Hackathon Result</label>
              <input
                type="text"
                placeholder="Results will be announced on. 2nd week of September"
                value={formData.hackathonResult}
                onChange={handleChange}
                name="hackathonResult"
              />
            </div>
            <div className="job-input-sections">
              <label>Hackathon Representing From</label>
              <input
                type="text"
                placeholder="IIT, Mumbai"
                value={formData.hackathonRepresentingFrom}
                onChange={handleChange}
                name="hackathonRepresentingFrom"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="mg-job-form">
            <div className="job-input-sections">
              <label>Who Can Participate</label>
              <select
                value={formData.whoCanParticipate}
                onChange={handleChange}
                name="whoCanParticipate"
                required
              >
                <option>Anyone</option>
                <option>Campus</option>
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mg-job-container">
      <div className="mg-job-first-section">
        <h1>Organise a Hackathon 🔔 </h1>
      </div>
      <div className="mg-job-second-section">
        <h2>Tell us about your requirement?</h2>
        <div>
          <form onSubmit={handleSubmit}>
            {renderFormSections()}
            {/* Step navigation */}
            {currentStep > 1 && (
              <button type="button" onClick={prevStep}>
                Previous
              </button>
            )}
            {currentStep < 4 && (
              <button type="button" onClick={nextStep}>
                Next
              </button>
            )}
            {currentStep === 4 && <button type="submit">Submit </button>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Hackathons;
