import React, { useState } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import moment from 'moment';
import MenuItems from '../../../dashboard/MenuItems';
import LogoBar from '../../../dashboard/LogoBar';
import TopBarComp from '../../../dashboard/TopBarComp';

const BulkAdmission = () => {
  const [csvData, setCsvData] = useState([]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
      // Skip the first row (header)
      const [, ...data] = jsonData;

      const transformedData = data.map(row => {
        const dobSerial = row[5]; // Assuming the DOB is in the 6th column (index 5)
        let formattedDob = '';

        if (dobSerial) {
          const date = moment(dobSerial, 'YYYYMMDD').format('DD/MM/YYYY'); // Adjust if needed
          formattedDob = date;
        }

        return {
          campusCode: process.env.REACT_APP_CAMPUS_CODE,
          ernNo: row[0],
          fullName: row[1],
          fatherName: row[2],
          motherName: row[3],
          gender: row[4],
          dob: formattedDob,
          university: row[6],
          semester: row[7],
          course: row[8],
          session: row[9],
          address: row[10],
          state: row[11],
          city: row[12],
          pincode: row[13],
          email: row[14],
          phone: row[15],
          nationality: row[16],
          category: row[17],
          pendingFees: row[18],
          password: row[19]
        };
      });

      setCsvData(transformedData);
    };

    reader.readAsBinaryString(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/bulkregistercms",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ users: csvData }),
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status === true) {
          await Swal.fire({
            icon: "success",
            title: "Users registered Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  });

  return (
    <div className='dash-menu'>
      <div className='sidemenu'>
        <div className='rightmenu-logo'>
          <LogoBar />
        </div>
        <MenuItems />
      </div>
      <div className="rightdash">
        <TopBarComp />
        <div className="graphs">
          <div className="container">
            <h1 className="mt-5">Bulk Admission</h1>
            <Form onSubmit={handleSubmit}>
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p>Drag 'n' drop a CSV or Excel file here, or click to select one</p>
              </div>
              <div style={{width:'100hw'}}></div>
              <Table bordered hover className="mt-3" style={{display:"block",width:'100%',overflowX:'scroll'}}>
                <thead>
                  <tr>
                    <th>Campus Code</th>
                    <th>Enrollment Number</th>
                    <th>Full Name</th>
                    <th>Father's Name</th>
                    <th>Mother's Name</th>
                    <th>Gender</th>
                    <th>Date of Birth</th>
                    <th>University</th>
                    <th>Semester</th>
                    <th>Course</th>
                    <th>Session</th>
                    <th>Address</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Pincode</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Nationality</th>
                    <th>Category</th>
                    <th>Pending Fees</th>
                    <th>Password</th>
                  </tr>
                </thead>
                <tbody>
                  {csvData.map((user, index) => (
                    <tr key={index}>
                      <td>{user.campusCode}</td>
                      <td>{user.ernNo}</td>
                      <td>{user.fullName}</td>
                      <td>{user.fatherName}</td>
                      <td>{user.motherName}</td>
                      <td>{user.gender}</td>
                      <td>{user.dob}</td>
                      <td>{user.university}</td>
                      <td>{user.semester}</td>
                      <td>{user.course}</td>
                      <td>{user.session}</td>
                      <td>{user.address}</td>
                      <td>{user.state}</td>
                      <td>{user.city}</td>
                      <td>{user.pincode}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.nationality}</td>
                      <td>{user.category}</td>
                      <td>{user.pendingFees}</td>
                      <td>{user.password}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button variant="success" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkAdmission;
