import React from 'react';
import ManageFaculty from '../../screens/cms/admin/ManageFaculty';

const DashboardManageFaculty = () => {
  return (
    <div>
    <div className='graphs'>
     <ManageFaculty />
    </div>

    </div>
  );
};

export default DashboardManageFaculty;
