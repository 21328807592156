import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";


const ExamForm = () => {
  const [formData, setFormData] = useState({
    studentName: '',
    fatherName: '',
    course: '',
    semester: '',
    session: '',
    subjects: '',
    examName: '',
  });
  const userToken = localStorage.getItem("userToken");
  
  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_AUTH}/user`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const user = response.data?.data[0];
        setFormData({...formData,university:user?.university,session:user?.session,studentName:user?.fullName,fatherName:user?.fatherName,course:user?.course});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data=JSON.stringify(formData);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/examform`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      data:data
    };

    axios
      .request(config)
      .then(async(response) => {
        await Swal.fire({
          icon: "success",
          title: "Exam form submitted Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.href='/dashboard';
      
      })
      .catch((error) => {
        console.log(error);
      });
 };

  return (
    <div className="container" style={{margin:'150px auto'}}>
      <h1 className="my-4">Exam Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="studentName" className="form-label">Student Name</label>
          <input type="text" className="form-control" id="studentName" name="studentName" value={formData.studentName} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="fatherName" className="form-label">Father's Name</label>
          <input type="text" className="form-control" id="fatherName" name="fatherName" value={formData.fatherName} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="course" className="form-label">Course</label>
          <input type="text" className="form-control" id="course" name="course" value={formData.course} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="course" className="form-label">University</label>
          <input type="text" className="form-control" id="university" name="university" value={formData.university} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="semester" className="form-label">Semester</label>
          <input type="text" className="form-control" id="semester" name="semester" value={formData.semester} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="session" className="form-label">Session</label>
          <input type="text" className="form-control" id="session" name="session" value={formData.session} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="subjects" className="form-label">Subjects (comma-separated)</label>
          <input type="text" className="form-control" id="subjects" name="subjects" value={formData.subjects} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="examName" className="form-label">Exam Name</label>
          <input type="text" className="form-control" id="examName" name="examName" value={formData.examName} onChange={handleChange} required />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default ExamForm;
