import React, { useState,useEffect} from "react";
import axios from "axios";
import SearchForm from "./SearchForm";
import Swal from "sweetalert2";

const MarksFaculty = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const userToken = localStorage.getItem("userToken");

  const handleSearch = async (filter) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:  `${process.env.REACT_APP_API}
/allusers?semester=${filter?.semester}&university=${filter?.university}&session=${filter?.session}&course=${filter?.course}&userType=student`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const newAttendanceData = response.data.data?.map((s) => ({
          userId: s._id,
          fullName: s.fullName,
          subjectCode: "",
          subjectName: "",
          semester: "",
          exam: "",
          session: "",
          totalMarks: 0,
          marks: 0,
        }));
        setAttendanceData(newAttendanceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (index, event) => {
    const values = [...attendanceData];
    values[index][event.target.name] = event.target.value;
    setAttendanceData(values);
  };

  const handleAddRow = () => {
    setAttendanceData([
      ...attendanceData,
      {
        userId: "",
        semester: "",
        fullName: "",
        exam: "",
        session: "",
        totalMarks: 0,
        subjectCode: "",
        subjectName: "",
        marks: 0,
      },
    ]);
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();

    let data = JSON.stringify({ marksRecords: attendanceData });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:  `${process.env.REACT_APP_API}
/bulkmarks`,
      headers: {
        authorization: userToken,
        "content-type":"application/json"
      },
      data:data
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Attendace updated Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
       
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          timer: 1500,
        });
        
        console.log(error);
      });
  };

  return (
    <div className="container" style={{marginTop:'110px'}}>
         <div className="space40"/>
      <h1>Manage Marks</h1>
      <SearchForm onSearch={handleSearch} />
      <div className="container">
        <form onSubmit={handleSubmit}>
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Semester</th>
                <th>Exam</th>
                <th>Subject Code</th>
                <th>Subject Name</th>
                <th>Total Marks</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData?.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      name="fullName"
                      value={row.fullName}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="semester"
                      value={row.semester}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="exam"
                      value={row.exam}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      name="subjectCode"
                      value={row.subjectCode}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="subjectName"
                      value={row.subjectName}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="totalMarks"
                      value={row.totalMarks}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="marks"
                      value={row.marks}
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="space40"/>
    </div>
  );
};

export default MarksFaculty;
