import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const AdmitCard = () => {
  const { id } = useParams();
  const [admitCard, setAdmitCard] = useState(null);
  const userToken=localStorage.getItem('userToken');


  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/examform/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
       setAdmitCard(response.data);
       console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const downloadAdmitCard = () => {
        window.print();    
  };

  if (!admitCard) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header text-center">
          <h2>Admit Card</h2>
        </div>
 
        <div className="card-body">
          <p><strong>Student Name:</strong> {admitCard.studentName}</p>
          <p><strong>Father's Name:</strong> {admitCard.fatherName}</p>
          <p><strong>University:</strong> {admitCard.university}</p>
          <p><strong>Course:</strong> {admitCard.course}</p>
          <p><strong>Semester:</strong> {admitCard.semester}</p>
          <p><strong>Session:</strong> {admitCard.session}</p>
          <p><strong>Subjects:</strong> {admitCard.subjects}</p>
          <p><strong>Exam Name:</strong> {admitCard.examName}</p>
          <button className="btn btn-primary" onClick={downloadAdmitCard}>Download Admit Card</button>
        </div>
      </div>
    </div>
  );
};

export default AdmitCard;
