import React from 'react';
import UniversityForm from '../../../screens/cms/admin/UniversityForm';

const UniversityCreate = () => {
  return (
    <div>
    <div className='graphs'>
      <UniversityForm />
    </div>

    </div>
  );
};

export default UniversityCreate;
