import React, { useState, useEffect } from "react";
import { Container, Table, Button } from "react-bootstrap";
import axios from "axios";
import { ShimmerTable } from 'react-shimmer-effects'; // Import ShimmerTable
import SearchForm from "../faculty/SearchForm";

const ManageFaculty = () => {
  const [faculty, setFaculty] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [noData, setNoData] = useState(false); // State to manage no data
  const userToken = localStorage.getItem("userToken");

  const handleSearch = async (filter) => {
    setLoading(true); // Start loading
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/allusers?userType=faculty`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data.data;
        setFaculty(data);
        setNoData(data.length === 0); // Set noData if there are no items
        setLoading(false); // End loading
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // End loading on error
      });
  };

  const handleView = (id) => {
    window.location.href = `/admin/viewfaculty/${id}`;
  };

  return (
    <Container style={{ marginTop: '110px' }}>
      <h1>Manage Faculty Members</h1>
      <SearchForm onSearch={handleSearch} />
      {loading ? (
        <ShimmerTable
          columns={[{ width: 50 }, { width: 150 }, { width: 150 }, { width: 200 }, { width: 100 }]} // Define column widths
          rows={10} // Number of rows for shimmer effect
        />
      ) : noData ? (
        <p>No Data Found</p> // Display when there is no data
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Department</th>
              <th>Email</th>
              <th>Manage Faculty</th>
            </tr>
          </thead>
          <tbody>
            {faculty.map((member, key) => (
              <tr key={member._id}>
                <td>{key + 1}</td>
                <td>{member.fullName}</td>
                <td>{member.department}</td>
                <td>{member.email}</td>
                <td>
                  <Button onClick={() => handleView(member._id)}>
                    View/Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default ManageFaculty;
