import React from 'react';
import SideMenu from './SideMenu';


const AdminDashboard = () => {
  return (
   <div className='dashboard_cms'>
     <SideMenu />
   </div>
  );
};

export default AdminDashboard;
