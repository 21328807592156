import React from 'react';
import SessionForm from '../../../screens/cms/admin/SessionForm';
import SocialFeedForm from '../../../screens/cms/admin/SocialFeed';

const SocialFeed = () => {
  return (
    <div className='graphs'>
    <div>
      <SocialFeedForm />
    </div>

    </div>
  );
};

export default SocialFeed;
