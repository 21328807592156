import React from 'react';
import ManageAlumni from '../../../screens/cms/admin/Alumni';

const ManageAlumniStudents = () => {
  return (
    <div>
    <div className='graphs'>
      <ManageAlumni />
    </div>

    </div>
  );
};

export default ManageAlumniStudents;
