import React from 'react';
import AdmissionFormAdminCreate from '../../../screens/cms/admin/AdmissionCreate';

const AdmissionNew = () => {
  return (
    <div>
    

    <div className='graphs'>
      <AdmissionFormAdminCreate />
    </div>

    </div>
  );
};

export default AdmissionNew;
