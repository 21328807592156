
import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdContactSupport } from "react-icons/md";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import moment from "moment-timezone";
const TopBarComp = () => {
    const [details, setDetails] = useState("");
    const userToken = localStorage.getItem("userToken");
    const [userProfile, setUserProfile] = useState();
  
    const userDetails = () => {
        let data = "";
    
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://api.multigrad.in/auth/user",
          headers: {
            authorization: userToken,
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        axios
          .request(config)
          .then((response) => {
            setDetails(response.data.data[0]);
            setUserProfile(response.data.data[0].userRole)
           
          })
          .catch((error) => {
            console.log(error);
          });
      };
      useEffect(() => {
        userDetails();
      }, []);

     

  return (
  
    <div className='topMenu'>
    <div className='pages'>
       <p>Pages/ Dashboard</p>
       <h2>Dashboard</h2>
    </div>
  
   <div className="supprt-and-other">
    {details?.isSubsription && 
   <div className="premium-feature subscription">
     <p><MdOutlineWorkspacePremium />{details?.subscriptionType}{` Membership - Next payment:`} {moment(details?.subscriptionEndDate).format('DD MMMM YY')}</p>
    
   </div>
}
{!details?.isSubsription && 
   <div className="premium-feature">
     <a href="https://campusconnect.multigrad.in/pricing"><MdOutlineWorkspacePremium />Plan & Billings</a>
   </div>
}
{details?.isSubsription && 
    <a href="https://calendly.com/amnshrma/30min?back=1" target="_blank"><MdContactSupport/>{` `}Customer Support</a>
}
   </div>
    <div className='menu-drop' >
     
      <a href="/settings">
         <div className="menu-drop-de">
            <img src={details?.userProfile || null}/>
            <p>{details?.fullName || null}
            <span>{details?.userType || null}</span>
            </p>
         </div>
         </a>
    </div>



    
 </div>
     
  );
};

export default TopBarComp;


