import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../assets/css/NewDashboard.css";
import { VscGraph } from "react-icons/vsc";
import { MdOutlineDataSaverOn } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { PiChalkboardTeacherBold } from "react-icons/pi";
import { HiInboxIn } from "react-icons/hi";
import { BsFolderPlus } from "react-icons/bs";
import { MdEmojiEvents } from "react-icons/md";
import { MdWork } from "react-icons/md";
import { IoIosCalendar } from "react-icons/io";
import { LiaUniversitySolid } from "react-icons/lia";
import { IoIosSettings } from "react-icons/io";
import Post from "../screens/modal/createPost";
import { TbLockAccess } from "react-icons/tb";
import { SiCoursera } from "react-icons/si";
import { IoMdExit } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaWpforms } from "react-icons/fa";
import { MdOutlineDynamicForm } from "react-icons/md";
import { FaUserClock } from "react-icons/fa";
import { LuUsers } from "react-icons/lu";
import { RiUserSearchLine } from "react-icons/ri";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { HiOutlineDocumentPlus } from "react-icons/hi2";
import { RiUserAddLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { FaRegCalendarPlus } from "react-icons/fa6";
import { LuCalendarCheck } from "react-icons/lu";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { BsShieldPlus } from "react-icons/bs";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { PiBagSimpleFill } from "react-icons/pi";
import { HiOutlineFolderPlus } from "react-icons/hi2";
import { BsMenuButton } from "react-icons/bs";
import { CiViewTimeline } from "react-icons/ci";


const MenuItems = () => {
  const [admissionsOpen, setAdmissionsOpen] = useState(false);
  const [studentsOpen, setStudentsOpen] = useState(false);
  const [facultyOpen, setFacultyOpen] = useState(false);
  const [hackathonOpen, setHackathonOpen] = useState(false);
  const [jobOpen, setJobOpen] = useState(false);
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [courseOpen, setCourseOpen] = useState(false);
  const [universityOpen, setUniversityOpen] = useState(false);
  const [sessionOpen, setSessionOpen] = useState(false);
  const [socialOpen, setSocialOpen] = useState(false);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function handlePost() {
    setShow(true);
  }

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes("/admissions")) {
      setAdmissionsOpen(true);
    } else if (pathname.includes("/students")) {
      setStudentsOpen(true);
    } else if (pathname.includes("/faculty")) {
      setFacultyOpen(true);
    } else if (pathname.includes("/course")) {
      setCourseOpen(true);
    } else if (pathname.includes("/subject")) {
      setSubjectOpen(true);
    } else if (pathname.includes("/university")) {
      setUniversityOpen(true);
    } else if (pathname.includes("/session")) {
      setSessionOpen(true);
    } else if (pathname.includes("/hackathon")) {
      setHackathonOpen(true);
    } else if (pathname.includes("/job")) {
      setJobOpen(true);
    }
    else if (pathname.includes("/feed")) {
      setSocialOpen(true);
    }
  }, [location.pathname]);

  const toggleAdmissionsDropdown = () => {
    setAdmissionsOpen(!admissionsOpen);
  };

  const toggleStudentsDropdown = () => {
    setStudentsOpen(!studentsOpen);
  };

  const toggleFacultyDropdown = () => {
    setFacultyOpen(!facultyOpen);
  };

  const toggleCourseDropdown = () => {
    setCourseOpen(!courseOpen);
  };

  const toggleSubjectDropdown = () => {
    setSubjectOpen(!subjectOpen);
  };
  const toggleSessionDropdown = () => {
    setSessionOpen(!sessionOpen);
  };
  const toggleUniversityDropdown = () => {
    setUniversityOpen(!universityOpen);
  };
  const toggleJobsDropdown = () => {
    setJobOpen(!jobOpen);
  };

  const toggleHackathonsDropdown = () => {
    setHackathonOpen(!hackathonOpen);
  };


  const toggleSocial = () => {
    setSocialOpen(!socialOpen);
  };

  function handleLogout() {
    localStorage.removeItem("userToken");
    navigate("/");
  }

  return (
    <div className="rightmenu-items">
      <NavLink exact to="/dashboard" activeClassName="active-menu-link">
        <span id="icon">
          <VscGraph />
        </span>{" "}
        Dashboard
      </NavLink>

      <div className="dropdown">
       <button onClick={toggleAdmissionsDropdown} activeClassName="active-submenu-link">
          <span id="icon">
            <MdOutlineDataSaverOn />
          </span>{" "}
          Admissions
        </button>
        {admissionsOpen && (
          <div className="dropdown-content" >
            <NavLink to="/admissions/new" activeClassName="active-submenu-link">
             <FaWpforms id="inic" />{` `} New Admission
            </NavLink>
            <NavLink to="/admissions/bulk" activeClassName="active-submenu-link">
             <MdOutlineDynamicForm id="inic" />{ ` `} Bulk Admission 
            </NavLink>
            <NavLink
              to="/admissions/requests"
              activeClassName="active-submenu-link"
            >
             <FaUserClock id="inic" />{` `} Admission Requests
            </NavLink>
          </div>
        )}
      </div>

      {/* Students Dropdown */}
      <div className="dropdown">
       <button onClick={toggleStudentsDropdown}>
          <span id="icon" >
            <BsPeopleFill />
          </span>{" "}
          Students
          </button>
        {studentsOpen && (
          <div className="dropdown-content">
            <NavLink
              to="/students/manage"
              activeClassName="active-submenu-link"
            >
            <LuUsers id="inic" />{` `}    Manage Students
            </NavLink>

            <NavLink
              to="/students/search"
              activeClassName="active-submenu-link"
            >
              <RiUserSearchLine id="inic" />{` `}   Search Student
            </NavLink>
            <NavLink
              to="/students/alumni"
              activeClassName="active-submenu-link"
            >
             <LuUsers id="inic" />{` `}    Manage Alumni
            </NavLink>
          </div>
        )}
      </div>

      <div className="dropdown">
       <button onClick={toggleFacultyDropdown}>
          <span id="icon">
            <PiChalkboardTeacherBold />
          </span>{" "}
          Faculty
          </button>
        {facultyOpen && (
          <div className="dropdown-content">
            <NavLink to="/faculty/manage" activeClassName="active-submenu-link">
            <FiUsers id="inic" />  Manage Faculty
            </NavLink>
            <NavLink to="/faculty/create" activeClassName="active-submenu-link">
            <RiUserAddLine id="inic" />  Create Faculty
            </NavLink>
          </div>
        )}
      </div>
      <div className="breaker" />
      <div className="dropdown">
       <button onClick={toggleCourseDropdown}>
          <span id="icon">
          <HiClipboardDocumentCheck />
          </span>{" "}
          Courses
          </button>
        {courseOpen && (
          <div className="dropdown-content">
            <NavLink
              to="/course/new"
              activeClassName="active-submenu-link"
            >
            <HiOutlineDocumentPlus id="inic" />  Create Courses
            </NavLink>
            <NavLink
              to="/course/all"
              activeClassName="active-submenu-link"
            >
              <HiClipboardDocumentCheck id="inic" />{` `}  View Courses
            </NavLink>
          </div>
        )}
      </div>

      <div className="dropdown">
       <button onClick={toggleSessionDropdown}>
          <span id="icon">
            <IoIosCalendar />
          </span>{" "}
          Session
          </button>
        {sessionOpen && (
          <div className="dropdown-content">
            <NavLink
              to="/session/new"
              activeClassName="active-submenu-link"
            >
            <FaRegCalendarPlus id="inic" />  Create Sessions
            </NavLink>
            <NavLink
              to="/session/all"
              activeClassName="active-submenu-link"
            >
              <LuCalendarCheck id="inic" /> View Sessions
            </NavLink>
          </div>
        )}
      </div>
      <div className="dropdown">
       <button onClick={toggleSubjectDropdown}>
          <span id="icon">
            <IoDocuments />
          </span>{" "}
          Subjects
          </button>
        {subjectOpen && (
          <div className="dropdown-content">
            <NavLink
              to="/subject/new"
              activeClassName="active-submenu-link"
            >
             <HiOutlineDocumentPlus id="inic" />   Create Subjects
            </NavLink>
            <NavLink
              to="/subject/all"
              activeClassName="active-submenu-link"
            >
            <HiClipboardDocumentCheck id="inic" />{` `}  View Subjects
            </NavLink>
          </div>
        )}
      </div>
      <div className="dropdown">
       <button onClick={toggleUniversityDropdown}>
          <span id="icon">
            <LiaUniversitySolid />
          </span>{" "}
          Universities
          </button>
        {universityOpen && (
          <div className="dropdown-content">
            <NavLink
              to="/university/new"
              activeClassName="active-submenu-link"
            >
            <BsShieldPlus id="inic" />  Create Universities
            </NavLink>
            <NavLink
              to="/university/all"
              activeClassName="active-submenu-link"
            >
            <HiOutlineAcademicCap id="inic" />  View Universities
            </NavLink>
          </div>
        )}
      </div>

      

      

      <div className="breaker" />
     
  

      <div className="dropdown">
       <button onClick={toggleSocial}>
     
        <span id="icon">
            <BsMenuButton />
          </span>{" "}
          Campus Connect
       
         
          </button>
        {socialOpen && (
          <div className="dropdown-content">
            <NavLink to="###notify" activeClassName="active-submenu-link" onClick={handlePost}>
            
            <BsFolderPlus id='inic' />  Notify 
            </NavLink>
            <NavLink to="/feed" activeClassName="active-submenu-link">
             <CiViewTimeline id='inic' /> Social Feed
            </NavLink>
          </div>
        )}
      </div>
     
      <div className="dropdown">
       <button onClick={toggleHackathonsDropdown}>
          <span id="icon">
            <MdEmojiEvents />
          </span>{" "}
          Hackathons
          </button>
        {hackathonOpen && (
          <div className="dropdown-content">
            <NavLink to="/hackathon/new" activeClassName="active-submenu-link">
            <FaRegCalendarPlus id="inic"  />
            Create Hackathon
            </NavLink>
            <NavLink to="/hackathons" activeClassName="active-submenu-link">
             <MdOutlineEmojiEvents id='inic' /> Hackathons
            </NavLink>
          </div>
        )}
      </div>

      <div className="dropdown">
       <button onClick={toggleJobsDropdown}>
          <span id="icon">
            <MdWork />
          </span>{" "}
        
          Jobs & Internship
          </button>
        {jobOpen && (
          <div className="dropdown-content">
            <NavLink to="/job/new" activeClassName="active-submenu-link">
            <HiOutlineFolderPlus id='inic' />
            Create Job/Internship
            </NavLink>
            <NavLink to="/jobs" activeClassName="active-submenu-link">
            <PiBagSimpleFill id='inic' />  Manage Jobs/Internship
            </NavLink>
          </div>
        )}
      </div>
      <div className="dropdown">
        <NavLink to="/messaging" activeClassName="active-menu-link">
          <span id="icon">
            <HiInboxIn />
          </span>{" "}
          Message
        </NavLink>
      </div>

      <div className="breaker" />

<div className="dropdown">
  <NavLink to="/roles-and-access" activeClassName="active-menu-link">
    <span id="icon">
      <TbLockAccess />
    </span>{" "}
    Roles & Access
  </NavLink>
</div>

      <div className="breaker" />
      <div className="dropdown">
        <NavLink to="/settings" activeClassName="active-menu-link">
          <span id="icon">
            <IoIosSettings />
          </span>{" "}
          Settings
        </NavLink>
      </div>
     

      <div className="dropdown footer-menu">
        <a onClick={handleLogout} activeClassName="active-submenu-link">
          <span id="icon">
            <IoMdExit />
          </span>{" "}
          Logout
        </a>
      </div>
      {show && <Post setShow={setShow} show={show} />}
    </div>
  );
};

export default MenuItems;
