import React from 'react';
import SessionForm from '../../../screens/cms/admin/SessionForm';

const SessionDetailsCreate = () => {
  return (
    <div>
    <div className='graphs'>
      <SessionForm />
    </div>

    </div>
  );
};

export default SessionDetailsCreate;
