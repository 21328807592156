import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Table, Form, Button, Container, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { NavLink, useLocation } from 'react-router-dom';

import MenuItems from '../../../dashboard/MenuItems';
import LogoBar from '../../../dashboard/LogoBar';
import TopBarComp from '../../../dashboard/TopBarComp';
const BulkRegistration = () => {
  const [users, setUsers] = useState([{campusCode:process.env.REACT_APP_CAMPUS_CODE, email: '', password: '', fullName: '', userType: 'student' }]);
  
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newUsers = [...users];
    newUsers[index][name] = value;
    setUsers(newUsers);
  };
 
  const handleAddRow = () => {
    setUsers([...users, {campusCode:process.env.REACT_APP_CAMPUS_CODEE, email: '', password: '', fullName: '', userType: 'student' }]);
  };

  const handleRemoveRow = (index) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/bulkregistercms",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({users:users}),
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status === true) {
          await Swal.fire({
            icon: "success",
            title: "Users registered Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
           window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  
  return (
    <div className='dash-menu'>
    <div className='sidemenu'>
      <div className='rightmenu-logo'>
        <LogoBar />
      </div>

      <MenuItems />


    </div>
    <div className="rightdash">
      
      
   <TopBarComp />
        
        
      <div className="graphs">

<div  className="container">
      <h1 className="mt-5">Add Faculty Member</h1>
      <Form onSubmit={handleSubmit}>
        <Table bordered hover>
          <thead>
            <tr>
            <th>Full Name</th>
              <th>Email</th>
              <th>Password</th>
              <th>User Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                    type="fullName"
                    name="fullName"
                    value={user.fullName}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </td>
              
                <td>
                  <Form.Control
                    as="select"
                    name="userType"
                    value={user.userType}
                    onChange={(e) => handleChange(index, e)}
                    required
                  >
                    <option value="">Select</option>
                    <option value="faculty">Faculty</option>
                  </Form.Control>
                </td>
                <td>
                  <Button variant="danger" onClick={() => handleRemoveRow(index)}>
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant="primary" onClick={handleAddRow}>
          Add Row
        </Button>
        <Button variant="success" type="submit" className="ml-2">
          Submit
        </Button>
      </Form>
    </div> </div> </div> </div>
  );
};

export default BulkRegistration;
