import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import Swal from "sweetalert2";

const SessionForm = () => {
  const [subject, setSubject] = useState({
    session: ""
  });
  const userToken = localStorage.getItem("userToken");
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id !== "new") {
      axios
        .get( `${process.env.REACT_APP_API}
/session/${id}`, {
          headers: {
            authorization: userToken,
          },
        })
        .then((response) => {
          setSubject(response.data);
          setIsEditing(true);
        })
        .catch((error) => {
          console.error("There was an error making the request:", error);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubject((prevSubject) => ({
      ...prevSubject,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      axios
        .patch( `${process.env.REACT_APP_API}
/session/${id}`, subject, {
          headers: {
            authorization: userToken,
          },
        })
        .then(async (response) => {
          console.log(response);
          await Swal.fire({
            icon: "success",
            title: "Session edited Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
          console.error("There was an error making the PUT request:", error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/session`, subject, {
          headers: {
            authorization: userToken,
          },
        })
        .then(async () => {
          await Swal.fire({
            icon: "success",
            title: "Session created Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.href = "/session/all";
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });

          console.error("There was an error making the POST request:", error);
        });
    }
  };

  return (
    <div className="container" style={{marginTop:'110px'}}>
      <div className="space40"/>
      
        <h1>{isEditing ? "Edit Session" : "Create Session"}</h1>
        <Form onSubmit={handleSubmit} className="space">
          <Form.Group controlId="course">
            <Form.Label>Session</Form.Label>
            <Form.Control
              type="text"
              name="session"
              value={subject.session}
              onChange={handleChange}
              required
            />
          </Form.Group>

          
          <Button variant="primary" type="submit">
            {isEditing ? "Update" : "Create"}
          </Button>
        </Form>
     
    </div>
  );
};

export default SessionForm;
