import React from 'react';
import SubjectList from '../../../screens/cms/admin/SubjectList';

const SubjectAll = () => {
  return (
    <div>
    <div className='graphs'>
      <SubjectList />
    </div>

    </div>
  );
};

export default SubjectAll;
