import React from 'react';
import ManageSubjects from '../../../screens/cms/admin/SubjectList';


const ManageSubject = () => {
  return (
    <div>
    <div className='graphs'>
      <ManageSubjects />
    </div>

    </div>
  );
};

export default ManageSubject;
