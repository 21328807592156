import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import SocialFeedComp from '../../../components/feed';
import { ShimmerPostItem } from "react-shimmer-effects";

const SocialFeedForm = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 const [isSticky, setIsSticky] = useState(false);
  const qrSectionRef = React.useRef(null);

  const handleScroll = () => {
    if (qrSectionRef.current) {
      const stickyOffset = qrSectionRef.current.offsetTop;
      setIsSticky(window.scrollY > stickyOffset);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const userToken = localStorage.getItem("userToken");

  const handleSearch = async () => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_AUTH}/homefeed/posts`,
        headers: {
          authorization: userToken,
        },
      };

      const response = await axios.request(config);
      setData(response.data.data || []); // Ensure data is always an array
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  if (loading) {
    return (
      <div className='social-feed'>
        <ShimmerPostItem
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={80}
          imageHeight={80}
          contentCenter
        />
      </div>
    );
  }

  if (error) {
    return <Container style={{ marginTop: '110px' }}>Error: {error.message}</Container>;
  }
  const handlePlay = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.multigrad.app';
  }
  

  return (
   <div className='socials'>
      <SocialFeedComp data={data} />
      <div className='parent-container'>
      <div className='qr-section'>
         <div className='qr-text'>Interact with friends, participate in hackathons, events and more</div>
         <div className='qr-code'>
           <img src="https://api.multigrad.in/open/media/?file=1722720701496.png" />
         </div>
         <div className='play' onClick={handlePlay}>
           <p>Get Full Access</p>
           <img src='https://api.multigrad.in/open/media/?file=1722720132567.png' />
         </div>
      </div>
      
      </div>
   </div>
  );
};

export default SocialFeedForm;
