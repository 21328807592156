import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import axios from "axios";

const AttendanceStudent = () => {
  const [attendance, setAttendance] = useState([]);
  const userToken = localStorage.getItem("userToken");

  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/attendances`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
       setAttendance(response.data);
       console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container style={{ marginTop: "125px" }}>
      <h2>My Attendance</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Subject</th>
            <th>Subject Code</th>
            <th>Total Days</th>
            <th>Presents</th>
            <th>Attendance %</th>
          </tr>
        </thead>
        <tbody>
          {attendance?.map((record, index) => (
            <tr key={record.id}>
              <td>{index + 1}</td>
              <td>{record.subjectName}</td>
              <td>{record.subjectCode}</td>
              <td>{record.totalLectures}</td>
              <td>{record.lecturesAttended}</td>
              <td>{(record.lecturesAttended / record.totalLectures).toFixed(2)*100}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AttendanceStudent;
