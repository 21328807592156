import React, { useState } from 'react';
import './feed.css';
import { SlLike } from "react-icons/sl";
import { TfiCommentAlt } from "react-icons/tfi";
import { AiFillLike } from "react-icons/ai";
import { BiLike } from "react-icons/bi";
import axios from 'axios';

const SocialFeedComp = ({ data }) => {
  const [visiblePosts, setVisiblePosts] = useState(5);
  const [posts, setPosts] = useState(data);
  const [comment, setComment] = useState('');
  const [activePost, setActivePost] = useState(null);

  const handleLoadMore = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 5);
  };

  const fetchPost = async (postId) => {
    const userToken = localStorage.getItem("userToken");
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/post/${postId}`,
      headers: { 
        'Authorization': userToken
      }
    };

    try {
      const response = await axios.request(config);
      if (response.data.success) {
        const updatedPost = response.data.post;
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post._id === postId ? updatedPost : post
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike =async (postId) => {
    let newLikedByMe = '';
    setPosts((prevPosts) =>
      prevPosts.map((post) => {
        if (post._id === postId) {
          const newLikedByMe = !post.likedByMe;
          const newTotalLikes = newLikedByMe ? post.totalLikes + 1 : post.totalLikes - 1;
          return { ...post, likedByMe: newLikedByMe, totalLikes: newTotalLikes };
        }
        return post;
      })
    );

   
    const userToken = localStorage.getItem("userToken");
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/post-like/${postId}`,
      headers: { 
        'Authorization': userToken, 
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ postLiked: newLikedByMe })
    };

    try {
      await axios.request(config);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = async (postId) => {
    const userToken = localStorage.getItem("userToken");
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/post-comment`,
      headers: { 
        'Authorization': userToken, 
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ postId, postComment: comment })
    };

    try {
      const response = await axios.request(config);
      if (response.data.success) {
        await fetchPost(postId); // Fetch and update the specific post data
        setComment('');
        setActivePost(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="social-feed">
      {posts?.length > 0 ? posts.slice(0, visiblePosts).map((post) => (
        <div key={post._id} className="post">
          <div className="post-header">
            <img src={post.userProfile} alt={`${post.fullName}'s profile`} className="user-profile" />
            <div className="user-info">
              <span className="full-name">
                {post.fullName} {post.userVerified === 'yes' && <span className="verified">✔️</span>}
              </span>
              <span className="user-type">{post.userType}</span>
            </div>
          </div>
          {post.postImage?.length > 0 && (
            <div className="post-image">
              <img src={post.postImage[0]} alt="Post" />
            </div>
          )}

          <div className="post-image">
            <p>{post.postQuestion}</p>
          </div>
       
          <div className='post-count'>
            <p>{post.totalLikes} people liked</p>
            <p>{post.totalComments} Comments</p>
          </div>
          <div className="post-footer">
            <div className="likes-comments">
              <span className="likes" onClick={() => handleLike(post._id)}>
                {post.likedByMe ? <div className='liked'><AiFillLike /> Liked</div> : <><BiLike /> Like</>}
              </span>
              <span className="comments" onClick={() => setActivePost(post._id)}>
                <TfiCommentAlt /> Comments
              </span>
            </div>
          </div>
          {activePost === post._id && (
              <div className="comment-section">
                <textarea 
                  value={comment} 
                  onChange={handleCommentChange} 
                  placeholder="Write a comment..." 
                />
                <button onClick={() => handleCommentSubmit(post._id)}>Submit</button>
              </div>
            )}
        </div>
      )) : (
        <p>No posts available</p>
      )}
      {visiblePosts < posts.length && (
        <button className="load-more" onClick={handleLoadMore}>Load More</button>
      )}
    </div>
  );
};

export default SocialFeedComp;
