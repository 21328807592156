import React from 'react';
import SessionList from '../../../screens/cms/admin/SessionList';
const SessionDetailsNew = () => {
  return (
    <div>
    <div className='graphs'>
      <SessionList />
    </div>

    </div>
  );
};

export default SessionDetailsNew;
