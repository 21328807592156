import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  AiFillEdit,
  AiFillDelete,
  AiOutlinePlus,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { MdExplore } from "react-icons/md";
import axios from "axios";
import "../../assets/css/hackathon.css";
import moment from "moment";
const Hackathons = () => {
  const userToken = localStorage.getItem("userToken");
  const [hackathon, setHackathon] = useState([]);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const [Participants, setParticipants] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.multigrad.in/auth/hackathon`,
        {
          headers: {
            Authorization: userToken,
          },
        }
      );
      setHackathon(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCountdown = (endTime) => {
    const now = moment();
    const end = moment(endTime);
    const duration = moment.duration(end.diff(now));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${days}D : ${hours}H : ${minutes}M`;
  };

  const deleteHackathon = async (hackathon) => {
    if (window.confirm("Are you sure you want to delete this hackathon ?")) {
      try {
        await axios.delete(
          `https://api.multigrad.in/auth/hackathon/${hackathon._id}`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const editHackathon = async (hackathon) => {
    navigate(`/hackathon/edit/${hackathon._id}`);
  };

  const handleParticipate = (value) => {

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/join-hackathon/${value._id}`,
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mg-job-container">
      <div className="hackathonsMainDiv">
        {hackathon.length === 0 ? (
          <p>No listings found!</p>
        ) : (
          hackathon.map((job, i) => (
            <div className="hackathonDiv">
              <div className="hackathonDivLeft">
                <h3>Hackathon</h3>
                <h1>{hackathon[i].hackathonName}</h1>
                <h3>Theme</h3>
                <p>{hackathon[i].hackathonTheme}</p>
                <div className="hackathonParticipants">
                  <p id="org">Organiser :</p>
                  <img src={hackathon[i].userProfilePic} />
                  <p>{hackathon[i].fullName}</p>
                </div>
                <div className="hackathonParticipants">
                  <p id="number">
                    Participants : <AiOutlineUsergroupAdd /> {Participants}{" "}
                    {hackathon[i].participants.length}
                  </p>
                </div>
                <div className="hackathonTags">
                  <p>{hackathon[i].hackathonMode}</p>
                  <p id="countdown">
                    {getCountdown(hackathon[i].hackathonRegistrationEndTime)}
                  </p>
                </div>
              </div>
              <div className="hackathonDivRight">
                <img src={hackathon[i].hackathonBanner} />
                <button
                  onClick={() => handleParticipate(hackathon[i])}
                  className={hackathon[i].button}
                >
                  {hackathon[i].button}
                </button>
              </div>
              {hackathon[i].createdBy !== "other" && (
                <div className="editDelete hackathonBtn">
                  <p id="edit" onClick={() => editHackathon(hackathon[i])}>
                    Edit
                  </p>
                  <p id="delete" onClick={() => deleteHackathon(hackathon[i])}>
                    Delete
                  </p>{" "}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Hackathons;
