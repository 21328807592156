import React, { useState,useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
import axios from 'axios';

const Subjects = () => {
  const [subjects,setSubjects] = useState([]
  );
  const userToken = localStorage.getItem("userToken");

  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/mysubjects`,
      headers: {
        "Content-Type": "application/json",
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);


  return (
    <Container style={{marginTop:'125px'}}>
      <h2>My Subjects</h2>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Course</th>
          <th>Branch</th>
          <th>Semester</th>
          <th>Subject Code</th>
          <th>Subject Name</th>
          <th>Total Lectures</th>
          <th>Credits</th>
          <th>Session</th>
        </tr>
      </thead>
      <tbody>
        {subjects?.map((subject) => (
          <tr key={subject._id}>
            <td>{subject.course}</td>
            <td>{subject.branch}</td>
            <td>{subject.semester}</td>
            <td>{subject.subjectCode}</td>
            <td>{subject.subjectName}</td>
            <td>{subject.totalLectures}</td>
            <td>{subject.credits}</td>
            <td>{subject.session}</td>
          </tr>
        ))}
      </tbody>
    </Table>

    </Container>
  );
};

export default Subjects;
