import React, { useState, useEffect } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { ShimmerTable } from 'react-shimmer-effects'; 

const SessionList = () => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading
  const userToken = localStorage.getItem("userToken");

  const handleSearch = async () => {
    setLoading(true); // Start loading
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/sessions`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSubjects(response.data);
        setLoading(false); // End loading
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // End loading on error
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Container style={{ marginTop: '110px' }}>
      <div>
        <h1>Session List</h1>
      </div>
      {loading ? (
        <ShimmerTable
          columns={[{ width: 200 }, { width: 150 }]} // Define column widths as needed
          rows={10} // Number of rows for shimmer effect
        />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Session</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {subjects?.map((subject) => (
              <tr key={subject._id}>
                <td>{subject.session}</td>
                <td>
                  <Button onClick={() => window.location.href = `/session/${subject._id}`}>
                    View/Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default SessionList;
