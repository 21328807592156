import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MessagesCom = ({ groupId, userToken, community }) => {
  const [messages, setMessages] = useState([]);
  const [imgLoad, setImgLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 400));
        const response = await axios.get(
          `https://api.multigrad.in/auth/chat/${groupId}`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
        setMessages(response.data.data);
        setImgLoad(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    setIsLoading(true);
    fetchChatData();
  }, [groupId, userToken]);

  return (
    <div className="msg-container-com">
      {isLoading ? (
        <Skeleton height={100} count={5} />
      ) : (
        messages
          .sort((a, b) => b - a)
          .map((m) => (
            <div className="msg-community " key={m.id}>
              <img
                src={m.sender.userProfile}
                onLoad={() => setImgLoad(true)}
                alt={m.sender.fullName}
              />
              <div className="msg-content-wrapper">
                <p className="text-underline">{m.sender.fullName}</p>
                <p className="msg-text">{m.message}</p>
              </div>
            </div>
          ))
      )}
    </div>
  );
};

export default MessagesCom;
