import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { ShimmerTable } from "react-shimmer-effects";
import SearchForm from "../faculty/SearchForm";

const ManageStudentsRequests = () => {
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(false); // New state for loading
  const userToken = localStorage.getItem("userToken");

  const handleSearch = (filter) => {
    setLoading(true); // Set loading to true when starting to fetch data
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/allusers?semester=${filter?.semester}&university=${filter?.university}&session=${filter?.session}&course=${filter?.course}&showAdmForm=false&userType=student`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFilteredStudents(response.data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false in case of an error
      });
  };

  const downloadDetails = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/alluser?&showAdmForm=false`,
      headers: {
        authorization: userToken,
      },
      responseType: 'blob', // Ensure the response type is set to 'blob' for binary data
    };
  
    axios
      .request(config)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'students_report.csv'; // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleShowAdmForm = (studentId) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/showadmform/${studentId}`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          icon: "success",
          title: "Admission Approved Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleView = (id) => {
    window.location.href = `/admin/view/${id}`;
  };

  const handleFees = (id) => {
    window.location.href = `/admin/fees/${id}`;
  };

  return (
    <div className="container" style={{ marginTop: "110px" }}>
      <h1>Admission Requests</h1>
      <SearchForm onSearch={handleSearch} />
      <div className="download-data">
        <Button onClick={downloadDetails}>Download</Button>
      </div>
      <div>
        {loading ? (
          <ShimmerTable
            columns={[
              { width: 150 },
              { width: 100 },
              { width: 150 },
              { width: 100 },
              { width: 200 },
            ]}
            rows={10} // Number of rows to show in shimmer
          />
        ) : filteredStudents.length === 0 ? (
          <p>No Request found</p>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Semester</th>
                <th>Course</th>
                <th>Session</th>
                <th>Manage Admission Form</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <tr key={student._id}>
                  <td>{student.fullName}</td>
                  <td>{student.semester}</td>
                  <td>{student.course}</td>
                  <td>{student.session}</td>
                  <td>
                    <Button
                      className={student.showAdmForm ? "btn-success" : "btn-danger"}
                      onClick={() => toggleShowAdmForm(student._id)}
                    >
                      {student.showAdmForm ? "Approve Admission" : "Approve Admission"}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ManageStudentsRequests;
