import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import axios from "axios";
import { NavLink, useLocation } from 'react-router-dom';
import { VscGraph } from "react-icons/vsc";
import { MdOutlineDataSaverOn } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { PiChalkboardTeacherBold } from "react-icons/pi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { TbMessage2 } from "react-icons/tb";
import MenuItems from "../../../dashboard/MenuItems";
import LogoBar from "../../../dashboard/LogoBar";
const Fees = () => {
  const [fees, setFees] = useState([]);

  const userToken = localStorage.getItem("userToken");
  const [admissionsOpen, setAdmissionsOpen] = useState(false);
  const [studentsOpen, setStudentsOpen] = useState(false);
  const [facultyOpen, setFacultyOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);

  const location = useLocation();

  // Effect to open dropdowns based on current path
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes('/admissions')) {
      setAdmissionsOpen(true);
    } else if (pathname.includes('/students')) {
      setStudentsOpen(true);
    } else if (pathname.includes('/faculty')) {
      setFacultyOpen(true);
    } else if (pathname.includes('/publish')) {
      setPublishOpen(true);
    }
  }, [location.pathname]);

  const toggleAdmissionsDropdown = () => {
    setAdmissionsOpen(!admissionsOpen);
  };

  const toggleStudentsDropdown = () => {
    setStudentsOpen(!studentsOpen);
  };

  const toggleFacultyDropdown = () => {
    setFacultyOpen(!facultyOpen);
  };

  const togglePublishDropdown = () => {
    setPublishOpen(!publishOpen);
  };

  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/myfees`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFees(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className='dash-menu'>
    <div className='sidemenu'>
      <div className='rightmenu-logo'>
        <LogoBar />
      </div>

    <MenuItems />


    </div>
    <div className="rightdash">
      
      
     
      <h2>My Fees</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Amount</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Download Receipt</th>
          </tr>
        </thead>
        <tbody>
          {fees?.map((fee, index) => (
            <tr key={fee._id}>
              <td>{index + 1}</td>
              <td>{fee.amount}</td>
              <td>{new Date(fee.dueDate).toLocaleDateString()}</td>
              <td>{fee.status}</td>
              <td><a href={`/student/feereceipt/${fee._id}`} >Download</a></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    </div>
  );
};

export default Fees;
