import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from "qrcode.react";
import Shape from '../../../assets/media/Shape 2.png';
import Shape2 from '../../../assets/media/Shape 2 (1).png';


const IdCard = () => {
    const { id } = useParams();
    const [student, setStudent] = useState({});
   const navigate = useNavigate();
    useEffect(() => {
        const loadData = () => {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://api.multigrad.in/secure/idcard?userId=${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            axios
                .request(config)
                .then((response) => {
                    setStudent(response.data?.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        loadData();
    }, [id]);

    const url = `https://multigrad.in/@${student.userName}`;

    const handlePrint = () => {
        window.print();
    };

    return (
      <>
        <div className='admit-card admit-card-print'>
            <img src={Shape2} id="shape" />
            <h2>ID Card</h2>
            <div className='admit-div'>
                <img src={student.userProfile} id="userprofile" alt="" />
                <div className='details'>
                    <label>Name</label>
                    <p>{student.fullName} </p>
                </div>
                
                <div className='details'>
                    <label>ID No.</label>
                    <p>{student.ernNo} </p>
                </div>
                <div className='details'>
                    <label>University</label>
                    <p>{student.university} </p>
                </div>
            </div>
            <div className='admit-div'>
                <QRCode value={url} className='small' />
                <div className='details'>
                    <label>Phone</label>
                    <p>{student.phone} </p>
                </div>
                <div className='details'>
                    <label>Gender</label>
                    <p>{student.gender} </p>
                </div>
                <div className='details'>
                    <label>Father Name</label>
                    <p>{student.fatherName} </p>
                </div>
            </div>
            

            <img src={Shape} id="shape" />

          
        </div>
       <div className='print'>
       
        <button onClick={handlePrint}>Print</button>
       </div>
        </>
    );
};

export default IdCard;
