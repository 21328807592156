import React from 'react';
import UniversityList from '../../../screens/cms/admin/UniversityList';

const UniversityLists = () => {
  return (
    <div>
    <div className='graphs'>
      <UniversityList />
    </div>

    </div>
  );
};

export default UniversityLists;
