import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import axios from "axios";

const AdmitCards = () => {
  const [subjects, setSubjects] = useState([]);
  const userToken = localStorage.getItem("userToken");

  const loadData = (event) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/myexamforms`,
      headers: {
        "Content-Type": "application/json",
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container style={{ marginTop: "125px" }}>
      <h2>My Subjects</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Session</th>
            <th>Course</th>
            <th>Exam Name</th>
            <th>Semester</th>
            <th>Subjects</th>
            <th>Date</th>
            <th>*</th>
          </tr>
        </thead>
        <tbody>
          {subjects?.map((subject) => (
            <tr key={subject._id}>
              <td>{subject.session}</td>
              <td>{subject.course}</td>
              <td>{subject.examName}</td>
              <td>{subject.semester}</td>
              <td>{subject.subjects}</td>
              <td>{subject.createdDate}</td>
              <td><button onClick={()=>window.location.href=`/admit-card/${subject._id}`}>Download</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AdmitCards;
