import React from 'react';
import CourseForm from '../../../screens/cms/admin/CourseForm';

const CoursesDetailsCreate = () => {
  return (
    <div>
    <div className='graphs'>
      <CourseForm />
    </div>

    </div>
  );
};

export default CoursesDetailsCreate;
