import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

const MyCourse = () => {
  const navigate = useNavigate();

  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobOrInternshipDescription: "",
    jobOrInternshipExperience: "",
    jobOrInternshipPriority: "",
    jobOrInternshipCompanyWeb: "https://",
    jobOrInternshipImage: "",
    jobOrInternshipCompany: "",
    jobOrInternshipPrivacy: "",
    jobOrInternshipLocation: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    let cI = "";

    if (formData.jobOrInternshipImage == "") {
      cI = "https://api.multigrad.in/open/media/?file=1689670312760.png";
    } else {
      cI = formData.jobOrInternshipImage;
    }

    const data = JSON.stringify({
      jobOrInternshipTitle: formData.jobTitle,
      jobOrInternshipDescription: formData.jobOrInternshipDescription,
      jobOrInternshipExperience: formData.jobOrInternshipExperience,
      jobOrInternshipPriority: formData.jobOrInternshipPriority,
      jobOrInternshipCompanyWeb: formData.jobOrInternshipCompanyWeb,
      jobOrInternshipImage: cI,
      jobOrInternshipCompany: formData.jobOrInternshipCompany,
      jobOrInternshipPrivacy: formData.jobOrInternshipPrivacy,
      jobOrInternshipLocation: formData.jobOrInternshipLocation,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/job`,
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        navigate("/jobs");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="mg-job-container">
      <div className="mg-job-first-section">
        <h1>Post a job 🔔 </h1>
        <p>
          Get the best talent with trending technologies. #1 platform where
          students can find what they want.
        </p>
      </div>
      <div className="mg-job-second-section">
        <h2>Tell us about your requirement?</h2>
        <div className="mg-job-form">
          <form onSubmit={handleSubmit}>
            <div className="job-input-sections">
              <label>Job Title *</label>
              <input
                type="text"
                placeholder="Eg: Software Engineer"
                value={formData.jobTitle}
                onChange={handleChange}
                name="jobTitle"
                required
              />
            </div>
            <div className="job-input-sections">
              <label>Job Description *</label>
              <textarea
                type="text"
                placeholder="Eg: We’re looking for a full-time senior software enginner with 10 years experience in development and leadership. This position in located in bengaluru."
                value={formData.jobOrInternshipDescription}
                onChange={handleChange}
                name="jobOrInternshipDescription"
                required
              />
            </div>
            <div className="job-input-sections">
              <label>Experience *</label>
              <select
                value={formData.jobOrInternshipExperience}
                onChange={handleChange}
                name="jobOrInternshipExperience"
                required
              >
                <option>Choose an option</option>
                <option>Batch -2024 </option>
                <option>Batch -2023 </option>
                <option>Batch -2022 </option>
                <option>Batch -2021 </option>
                <option>1+ Years of Experience or Fresher</option>
                <option>0-2 Years of Experienced</option>
                <option>0-3 Years of Experienced</option>
                <option>3+ Years of Experienced</option>
                <option>Experience not required!</option>
              </select>
            </div>
            <div className="job-input-sections">
              <label>Employment Type *</label>
              <select
                value={formData.jobOrInternshipPriority}
                onChange={handleChange}
                name="jobOrInternshipPriority"
              >
                <option>Choose an option</option>
                <option>Full-Time</option>
                <option>Part-Time</option>
                <option>Internship</option>
              </select>
            </div>
            <div className="job-input-sections">
              <label>Where peoples can apply *</label>
              <input
                type="text"
                placeholder="Eg: https://multigrad.in/careers/software-engineer/002"
                value={formData.jobOrInternshipCompanyWeb}
                onChange={handleChange}
                name="jobOrInternshipCompanyWeb"
                required
              />

              <p id="special-note">
                Note:- This is where people go to apply for your job.
              </p>
            </div>
            <div className="job-input-sections">
              <label>Company Name*</label>
              <input
                type="text"
                placeholder="Eg: Fightage Private Limited"
                value={formData.jobOrInternshipCompany}
                onChange={handleChange}
                name="jobOrInternshipCompany"
                required
              />
            </div>
            <div className="job-input-sections">
              <label>Company Logo </label>
              <input
                type="text"
                placeholder="Eg: https://multigrad.in/logo-512.png"
                value={formData.jobOrInternshipImage}
                onChange={handleChange}
                name="jobOrInternshipImage"
              />
            </div>
            <div className="job-input-sections">
              <label>Who Can Apply? *</label>
              <select
                value={formData.jobOrInternshipPrivacy}
                onChange={handleChange}
                name="jobOrInternshipPrivacy"
                required
              >
                <option value="">Select Degree</option>
                <option value="Bachelor of Animation (B.Anim)">Anyone</option>
                <option value="Bachelor of Technology (B.Tech.)">
                  Bachelor of Technology (B.Tech.)
                </option>
                <option value="Bachelor of Medicine, Bachelor of Surgery (MBBS)">
                  Bachelor of Medicine, Bachelor of Surgery (MBBS)
                </option>
                <option value="Bachelor of Business Administration (BBA)">
                  Bachelor of Business Administration (BBA)
                </option>
                <option value="Bachelor of Commerce (B.Com.)">
                  Bachelor of Commerce (B.Com.)
                </option>
                <option value="Bachelor of Science (B.Sc.)">
                  Bachelor of Science (B.Sc.)
                </option>
                <option value="Bachelor of Arts (B.A.)">
                  Bachelor of Arts (B.A.)
                </option>
                <option value="Bachelor of Pharmacy (B.Pharm)">
                  Bachelor of Pharmacy (B.Pharm)
                </option>
                <option value="Bachelor of Design (B.Des.)">
                  Bachelor of Design (B.Des.)
                </option>
                <option value="Bachelor of Architecture (B.Arch.)">
                  Bachelor of Architecture (B.Arch.)
                </option>
                <option value="Bachelor of Laws (LLB)">
                  Bachelor of Laws (LLB)
                </option>
                <option value="Bachelor of Computer Applications (BCA)">
                  Bachelor of Computer Applications (BCA)
                </option>
                <option value="Bachelor of Dental Surgery (BDS)">
                  Bachelor of Dental Surgery (BDS)
                </option>
                <option value="Bachelor of Fine Arts (BFA)">
                  Bachelor of Fine Arts (BFA)
                </option>
                <option value="Bachelor of Education (B.Ed.)">
                  Bachelor of Education (B.Ed.)
                </option>
                <option value="Bachelor of Hotel Management (BHM)">
                  Bachelor of Hotel Management (BHM)
                </option>
                <option value="Bachelor of Journalism and Mass Communication (BJMC)">
                  Bachelor of Journalism and Mass Communication (BJMC)
                </option>
                <option value="Bachelor of Physiotherapy (BPT)">
                  Bachelor of Physiotherapy (BPT)
                </option>
                <option value="Bachelor of Fashion Design (BFD)">
                  Bachelor of Fashion Design (BFD)
                </option>
                <option value="Bachelor of Science in Nursing (B.Sc. Nursing)">
                  Bachelor of Science in Nursing (B.Sc. Nursing)
                </option>
                <option value="Bachelor of Social Work (BSW)">
                  Bachelor of Social Work (BSW)
                </option>
                <option value="Bachelor of Ayurvedic Medicine and Surgery (BAMS)">
                  Bachelor of Ayurvedic Medicine and Surgery (BAMS)
                </option>
                <option value="Bachelor of Occupational Therapy (BOT)">
                  Bachelor of Occupational Therapy (BOT)
                </option>
                <option value="Bachelor of Business Management (BBM)">
                  Bachelor of Business Management (BBM)
                </option>
                <option value="Bachelor of Veterinary Science (BVSc)">
                  Bachelor of Veterinary Science (BVSc)
                </option>
                <option value="Bachelor of Event Management (BEM)">
                  Bachelor of Event Management (BEM)
                </option>
                <option value="Bachelor of Animation (B.Anim)">
                  Bachelor of Animation (B.Anim)
                </option>
              </select>
            </div>
            <div className="job-input-sections">
              <label>Job Location *</label>
              <input
                type="text"
                placeholder="Eg: Delhi"
                value={formData.jobOrInternshipLocation}
                onChange={handleChange}
                name="jobOrInternshipLocation"
              />
            </div>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyCourse;
