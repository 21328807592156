import React, { useState} from "react";
import axios from "axios";

const MarksStudent = () => {
  const [semester, setSemester] = useState("");
  const [data, setData] = useState(null);
  const userToken=localStorage.getItem("userToken");

  const handleSubmit = async (e) => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API}/mymarks?semester=${semester}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      };
  
      axios
        .request(config)
        .then((response) => {
          setData(response.data);        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <div className="mark-container">
      <h1>University Marksheet</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Semester:
          <input
            type="text"
            value={semester}
            onChange={(e) => setSemester(e.target.value)}
          />
        </label>
        <button type="submit">Get Marksheet</button>
      </form>
      {data&& (
        <div className="marksheet">
          <h2>Marksheet Details</h2>
          <div className="user-details">
            <p>
              <strong>Name:</strong> {data?.user.fullName}
            </p>
            <p>
              <strong>Email:</strong> {data?.user.email}
            </p>
            <p>
              <strong>Father's Name:</strong> {data?.user.fatherName}
            </p>
            <p>
              <strong>University:</strong> {data?.user.university}
            </p>
            <p>
              <strong>Course:</strong> {data?.user.course}
            </p>
            <p>
              <strong>Session:</strong> {data?.user.session}
            </p>
            <p>
              <strong>ERN No:</strong> {data?.user.ernNo}
            </p>
          </div>
          <table className="marks-table">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Exam</th>
                <th>Marks</th>
                <th>Total Marks</th>
              </tr>
            </thead>
            <tbody>
              {data?.marksheet.map((mark) => (
                <tr key={mark._id}>
                  <td>{mark.subjectName}</td>
                  <td>{mark.exam}</td>
                  <td>{mark.marks}</td>
                  <td>{mark.totalMarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MarksStudent;
