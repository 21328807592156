import React,{useState,useEffect} from 'react';
import { Container, Table} from 'react-bootstrap';
import axios from 'axios';


const CourseList = () => {
  
  const [subjects,setSubjects] = useState([]
  );
  const userToken = localStorage.getItem("userToken");


  const handleSearch = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/courses`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSubjects(response.data);

        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [])
  

  return (
    <Container style={{marginTop:'110px'}}>
      <div >
      <h1>Course List</h1>
      {/* <button className="mx-5 btn-primary" onClick={()=>window.location.href='/admin/subject/new'}>+ Add Subject</button> */}
      </div>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Course Name</th>
          <th>No. of Semester</th>
          <th>Fees</th>
          <th>Manage</th>
        </tr>
      </thead>
      <tbody>
        {subjects?.map((subject) => (
          <tr key={subject._id}  >
            <td>{subject.name}</td>
            <td>{subject.noOfSem}</td>
            <td>Rs. {subject.fees}</td>
            <td><button onClick={()=>window.location=`/course/${subject._id}`}>View/Edit</button></td>
          </tr>
        ))}
      </tbody>
    </Table>
    </Container>
  );
};

export default CourseList;
