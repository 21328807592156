import React from 'react';
import SubjectForm from '../../../screens/cms/admin/SubjectForm';
const SubjectListCreate = () => {
  return (
    <div>
    <div className='graphs'>
      <SubjectForm />
    </div>

    </div>
  );
};

export default SubjectListCreate;
