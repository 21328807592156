import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeGraphs from './dashboardCharts/HomeGraphs';
import AdmissionNew from './dashboardCharts/AdminPanel/AdmissionNew';
import AdmissionRequests from './dashboardCharts/AdminPanel/AdmissionRequests';
import DashboardManageStudents from './dashboardCharts/ManageStudents';
import ManageStudentById from './dashboardCharts/AdminPanel/ManageStudentById';
import DashboardManageFaculty from './dashboardCharts/ManageFaculty';
import ManageSubject from './dashboardCharts/AdminPanel/ManageSubject';
import ManageSubjectNew from './dashboardCharts/AdminPanel/ManageSubjectNew';
import TopBarComp from './TopBarComp';
import Message from './dashboardCharts/AdminPanel/Messaging';
import Settings from './dashboardCharts/AdminPanel/Settings';
import DashHackathons from './dashboardCharts/AdminPanel/Hackathons';
import ManageHackathons from './dashboardCharts/AdminPanel/ManageHackathons';
import ManageJobs from './dashboardCharts/AdminPanel/ManageJobs';
import Jobs from './dashboardCharts/AdminPanel/Jobs';
import CoursesDetails from './dashboardCharts/AdminPanel/Courses';
import CoursesDetailsCreate from './dashboardCharts/AdminPanel/CourseCreate';
import SessionDetailsNew from './dashboardCharts/AdminPanel/SessionDetailsNew';
import SessionDetailsCreate from './dashboardCharts/AdminPanel/SessionCreate';
import SubjectListCreate from './dashboardCharts/AdminPanel/SubjectCreate';
import SubjectAll from './dashboardCharts/AdminPanel/SubjectAll';
import UniversityLists from './dashboardCharts/AdminPanel/UniversityAll';
import UniversityCreate from './dashboardCharts/AdminPanel/UniversityCreate';
import SearchStudentPage from './dashboardCharts/AdminPanel/SearchStudent';
import ManageAlumniStudents from './dashboardCharts/AdminPanel/ManageAlumni';
import SocialFeed from './dashboardCharts/AdminPanel/SocialFeed';

const TopMenu = () => {
  const location = useLocation();
  const { pathname } = location; 
 
 
  const renderDashboardComponent = () => {
    switch (true) {
      case pathname.includes('/dashboard'):
        return <HomeGraphs />;
      case pathname.includes('/admissions/new'):
        return <AdmissionNew />;
      case pathname.includes('/admissions/requests'):
        return <AdmissionRequests />;
      case pathname.includes('/students/manage'):
        return <DashboardManageStudents />;
        case pathname.includes('/faculty/manage'):
          return <DashboardManageFaculty />;
        case pathname.includes('/courses/subjects'):
            return <ManageSubject />;
            case pathname.includes('/courses/subject/new'):
              return <ManageSubjectNew />;
              case pathname.includes('/messaging'):
                return <Message />;
                case pathname.includes('/settings'):
                  return <Settings />;
                  case pathname.includes('/hackathons'):
                    return <DashHackathons />;
                    case pathname.includes('/hackathon/new'):
                    return <ManageHackathons />;
                    case pathname.includes('/job/new'):
                    return <ManageJobs />;
                    case pathname.includes('/jobs'):
                    return <Jobs />;
                    case pathname.includes('/course/all'):
                      return <CoursesDetails />;
                      case pathname.includes('/course/new'):
                        return <CoursesDetailsCreate />;
                        case pathname.includes('/session/new'):
                          return <SessionDetailsCreate />;
                          case pathname.includes('/session/all'):
                          return <SessionDetailsNew />;
                          case pathname.includes('/subject/new'):
                          return <SubjectListCreate />;
                          case pathname.includes('/subject/all'):
                            return <SubjectAll />;
                            case pathname.includes('/university/all'):
                              return <UniversityLists />;
                              case pathname.includes('/university/new'):
                                return <UniversityCreate />;
                                case pathname.includes('/students/search'):
                                  return <SearchStudentPage />;
                                  case pathname.includes('/students/alumni'):
                                    return <ManageAlumniStudents />;
                                    case pathname.includes('/feed'):
                                    return <SocialFeed />;
      default:
        return null;
    }
  };

  return (
    <div className="rightdash">
      <TopBarComp />
      {renderDashboardComponent()}
    </div>
  );
};

export default TopMenu;
