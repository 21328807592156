import React from 'react';
import ManageStudents from '../../screens/cms/admin/ManageStudents';

const DashboardManageStudents = () => {
  return (
    <div>
    <div className='graphs'>
      <ManageStudents />
    </div>

    </div>
  );
};

export default DashboardManageStudents;
