import React, { useState, useEffect } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { ShimmerTable } from 'react-shimmer-effects'; 

const SubjectList = () => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [noData, setNoData] = useState(false); // State to manage no data
  const userToken = localStorage.getItem("userToken");

  const handleSearch = async () => {
    setLoading(true); // Start loading
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/subjects`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        setSubjects(data);
        setNoData(data.length === 0); // Set noData if there are no items
        setLoading(false); // End loading
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // End loading on error
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Container style={{ marginTop: '110px' }}>
      <div>
        <h1>Subject List</h1>
        {/* <Button className="mx-5 btn-primary" onClick={() => window.location.href='/admin/subject/new'}>+ Add Subject</Button> */}
      </div>
      {loading ? (
        <ShimmerTable row={5} col={5} />
      ) : noData ? (
        <p>No Data Found</p> // Display when there is no data
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Course</th>
              <th>Branch</th>
              <th>Semester</th>
              <th>Subject Code</th>
              <th>Subject Name</th>
              <th>Total Lectures</th>
              <th>Credits</th>
              <th>Session</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject) => (
              <tr key={subject._id}>
                <td>{subject.course}</td>
                <td>{subject.branch}</td>
                <td>{subject.semester}</td>
                <td>{subject.subjectCode}</td>
                <td>{subject.subjectName}</td>
                <td>{subject.totalLectures}</td>
                <td>{subject.credits}</td>
                <td>{subject.session}</td>
                <td>
                  <Button onClick={() => window.location.href = `/subject/${subject._id}`}>View/Edit</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default SubjectList;
