import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import Swal from "sweetalert2";
function Settings() {
  const userToken = localStorage.getItem("userToken");
  const [userDetails, setUserDetails] = useState("");
  const [activeSection, setActiveSection] = useState("General");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    campusCode: "",
    subjectTeaches: "",
    classOrCourse: "",
    stream: "",
    userType: "",
    instituteType: "",
    userProfile: "",
    skills: [],
    roadMapName: "",
    createdDate: "",
    userRole: "",
    gender: "",
    userVerified: "",
    semester: "",
    userFacebook: "",
    userInstagram: "",
    userLinkedin: "",
    userWebsite: "",
    userName: "",
  });
  const [upload, setUploading] = useState({
    show:false,
    msg:""
  });
  const [image, setImage] = useState(null);
  const [imageRes, setImageRes] = useState("");
  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
  };


  const handleLogo = (event) => {
    setUploading({show:true,msg:"Uploading..."});
    event.preventDefault();
    const data = new FormData();
    data.append("mediaKey", image);
    const config = {
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .post("https://api.multigrad.in/auth/media-upload", data, config)
      .then((response) => {
        setFormData({...formData,userProfile:response.data.mediaKey});
        setUploading({show:true,msg:"Uploaded"});
      })
      .catch((error) => {console.log(error)});
  };


  useEffect(() => {
    fetchUserDetails();
  }, []);
  async function fetchUserDetails() {
    try {
      const response = await axios.get("https://api.multigrad.in/auth/user/", {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      });
      const userData = response.data.data[0];

      setUserDetails(userData);
      setFormData({
        ...formData,
        fullName: userData.fullName,
        email: userData.email,
        phone: userData.phone,
        campusCode: userData.campusCode,
        subjectTeaches: userData.subjectTeaches,
        classOrCourse: userData.classOrCourse,
        stream: userData.stream,
        userType: userData.userType,
        instituteType: userData.instituteType,
        userProfile: userData.userProfile,
        skills: userData.skills,
        roadMapName: userData.roadMapName,
        userRole: userData.userRole,
        gender: userData.gender,
        semester: userData.semester,
        userFacebook: userData.userFacebook,
        userInstagram: userData.userInstagram,
        userLinkedin: userData.userLinkedin,
        userWebsite: userData.userWebsite,
        userName: userData.userName,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }

  const handleMenuClick = (sectionName) => {
    setActiveSection(sectionName);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    let data = formData;

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/user",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: `Your account has been updated.`,
          showConfirmButton: false,
          timer: 800,
        });
        fetchUserDetails();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container>
      <div className="setting-page">
        <div className="top-menu">
          <div className="top-menu-profile">
            <img src={userDetails?.userProfile} alt={userDetails?.fullName} />
          </div>
          <div className="top-menu-fullname">
            <h1>
              {userDetails?.fullName}/{activeSection}
            </h1>
            <p>Update your organisation code and manage your account</p>
          </div>
        </div>
        <div className="setting-menu">
          <p
            onClick={() => handleMenuClick("General")}
            className={activeSection}
          >
            General
          </p>
          <p onClick={() => handleMenuClick("Edit Profile")}>Edit Profile</p>
          <p onClick={() => handleMenuClick("Social Profiles")}>
            Social Profiles
          </p>
        
        </div>
        <div className="setting-content">
          {activeSection === "General" && (
            <div className="right-sidebar">
              <div className="menu-inputs one">
                <label>Organisation Code</label>
                <input
                  type="text"
                  name="campusCode"
                  placeholder="multigrad"
                  value={formData.campusCode}
                  onChange={handleChange}
                  disabled
                />
                <p>
                  Your online organisation URL : {` `}
                  <a
                    href={`https://www.multigrad.in/@${formData.campusCode}`}
                    target="_blank"
                    id="url"
                  >
                    https://multigrad.in/@{formData.campusCode}
                  </a>
                </p>
              </div>
            
              <div className="menu-inputs">
              <label>User Profile</label>
              <input className="pb-5"
                type="file"
                placeholder=""
                onChange={handleChangeImage}
                name="mediaKey"
              />
              <button className="mt-3 btn-primary" onClick={handleLogo}>Upload</button>
              <p>{upload.show?upload.msg:""}</p>
            </div>

              <div className="menu-inputs">
                <label>Email Address</label>
                <input
                  type="mail"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="your_name@example.com"
                  disabled
                />
              </div>
            </div>
          )}
          {activeSection === "Edit Profile" && (
            <div className="right-sidebar">
              <div className="menu-inputs one">
                <label>Full Name</label>
                <input
                  type="mail"
                  name="fullName"
                  value={formData?.fullName}
                  onChange={handleChange}
                  placeholder="your_name"
                />
              </div>

             

              <div className="menu-inputs">
                <label>Bio</label>
                <textarea
                  type="mail"
                  name="userBio"
                  value={formData.userBio}
                  onChange={handleChange}
                  placeholder="Brief description for your profile. URLs are hyperlinked."
                  id="bio"
                />
                <p></p>
              </div>
            </div>
          )}
          {activeSection === "Social Profiles" && (
            <div className="right-sidebar">
              <div className="menu-inputs one">
                <label>Instagram</label>
                <input
                  type="text"
                  name="userInstagram"
                  value={formData.userInstagram}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <div className="menu-inputs">
                <label>Facebook</label>
                <input
                  type="text"
                  name="userFacebook"
                  value={formData.userFacebook}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <div className="menu-inputs">
                <label>Twitter</label>
                <input
                  type="text"
                  name="userTwitter"
                  value={formData.userTwitter}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <div className="menu-inputs">
                <label>Website</label>
                <input
                  type="text"
                  name="userWebsite"
                  value={formData.userWebsite}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
          )}
        
          <div className="save-Changes">
            <button onClick={handleSaveChanges}>Save Changes</button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Settings;
