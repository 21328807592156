import React from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Menu from "../assets/media/menu.svg";
import LogoLightMedizone from "../assets/media/medizone.webp";

const Header2 = () => {
  const handleStartFree = () => {
    window.location = "/";
  };
  const userToken = localStorage.getItem("userToken");

  const handleLogout = () => {
    localStorage.clear();
    window.location.href='/';
  };

  return (
    <>
      <Navbar bg="" expand="lg" className="nav2 mobile-navbar">
        <Container id="navTop">
          <Navbar.Brand href="/">
            <img src={LogoLightMedizone} alt="multigrad.in" id="logo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll">
            <img id="menu" src={Menu} alt="multigrad.in" />
          </Navbar.Toggle>

          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <NavLink
                to="/register"
                className="nav-link"
                activeClassName="active"
              >
                Enquiry Form
              </NavLink>

              {userToken && <NavLink
                to="/dashboard"
                className="nav-link"
                activeClassName="active"
              >
                Dashboard
              </NavLink>}
            </Nav>
            <div className="d-flex header-btn">
              {userToken ? (
                <button id="primary-btn" onClick={handleLogout}>
                  Logout
                </button>
              ) : (
                <button id="primary-btn" onClick={handleStartFree}>
                  Login
                </button>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header2;
