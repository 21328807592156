import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";

function LoginAccount() {
  const [formData, setFormData] = useState({});
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    if (userToken) {
      window.location = "/dashboard";
    }
  }, []);

  const handleSendOtp = (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    let data = JSON.stringify({
      email: formData.email,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/send-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        setLoading(false); // Stop loading
        if (response.data.success === true) {
          setOtpSent(true);
          Swal.fire({
            icon: "success",
            title: "OTP sent Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await Swal.fire({
            icon: "error",
            title: "User not found!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        setLoading(false); // Stop loading
        Swal.fire({
          icon: "error",
          title: "User not found!",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      });
  };

  const handleVerifyOtp = (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    let data = JSON.stringify({
      email: formData.email,
      otp: formData.otp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        setLoading(false); // Stop loading
        await Swal.fire({
          icon: "success",
          title: "Logged in Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem("userToken", response.data?.userToken);
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        setLoading(false); // Stop loading
        Swal.fire({
          icon: "error",
          title: "Invalid OTP!",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      });
  };

  const handleGuestLogin = () => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjZhZWFkMDhmMjliM2FjYmY0ZjhiZmZmIn0sImlhdCI6MTcyMjcyMzkwOH0.T9Gx97yIxG8OsOX1deUiosVtwzcXEYATi77YhH07Rak";
    localStorage.setItem("userToken", token);
    Swal.fire({
      icon: "success",
      title: "Logged in as Guest!",
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.href = "/dashboard";
  };

  return (
    <Container className="signup">
      <div className="signup-page" style={{ marginTop: "125px" }}>
        <h1>Start using Multigrad College</h1>
        <p>Please click and continue</p>


      
        <button
          variant="secondary"
          className="col-12 customPopupBtn"
          onClick={handleGuestLogin}
          disabled={loading}
          style={{ marginTop: "10px" }}
        >
          Guest Login
        </button>
      </div>
    </Container>
  );
}

export default LoginAccount;
