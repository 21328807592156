import React, { useState } from "react";
import { Table, NavDropdown, Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import { ShimmerTable } from "react-shimmer-effects";
const SearchStudent = () => {
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(false); // New state for loading
  const userToken = localStorage.getItem("userToken");

  const handleSearch = (ernNo) => {
    setLoading(true); // Set loading to true when starting to fetch data
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/allusers?ernNo=${ernNo}`,
      headers: {
        authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setFilteredStudents(response.data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false in case of an error
      });
  };

  const handleView = (id) => {
    window.location.href = `/admin/view/${id}`;
  };

  const handleFees = (id) => {
    window.location.href = `/admin/fees/${id}`;
  };

  const handleAdmitCard = (id) => {
    window.location.href = `/student/idcard/${id}`;
  };

  const limitToTwoWords = (text) => {
    const words = text.split(" ");
    return words.length <= 2 ? text : `${words.slice(0, 2).join(" ")}...`;
  };

  return (
    <div className="container" style={{ marginTop: "120px" }}>
      <h1>Search Students</h1>
      <div className="search-form top">
        <input
          type="text"
          placeholder="Enter ERN No."
          id="ern"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(e.target.value);
            }
          }}
        />
        <button onClick={() => handleSearch(document.querySelector("input").value)}>Search</button>
      </div>
      <div className="top">
        {loading ? (
          <div className="text-center">
            <ShimmerTable row={5} col={5} />
          </div>
        ) : filteredStudents.length === 0 ? (
          <p>No data found</p>
        ) : (
          <Table striped bordered hover className="top">
            <thead>
              <tr>
                <th>Sn</th>
                <th>ERN No.</th>
                <th>Name</th>
                <th>Sem.</th>
                <th>Course</th>
                <th>Session</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student, key) => (
                <tr key={student._id}>
                  <td>{key + 1}</td>
                  <td>{student.ernNo}</td>
                  <td>{student.fullName}</td>
                  <td>{student.semester}</td>
                  <td>{limitToTwoWords(student.course)}</td>
                  <td>{student.session}</td>
                  <td className="manage-s">
                      <NavDropdown title="Manage">
                        <NavDropdown.Item>
                          <button onClick={() => handleView(student._id)}>View/Edit</button>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <button onClick={() => handleFees(student._id)}>Add/View Fees</button>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <button onClick={() => handleAdmitCard(student._id)}>Admit Card</button>
                        </NavDropdown.Item>
                     
                      </NavDropdown>
                    </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default SearchStudent;
