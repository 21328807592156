import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiFillEdit, AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { MdExplore } from "react-icons/md";
const MyCourse = () => {
  const [jobs, setJobs] = useState([]);
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/auth/web/jobs`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
        setJobs(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const deleteJob = async (job) => {
    if (window.confirm("Are you sure you want to delete this job?")) {
      try {
        await axios.delete(`https://api.multigrad.in/auth/job/${job._id}`, {
          headers: {
            Authorization: userToken,
          },
        });
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const editJob = async (job) => {
    navigate(`/job/edit/${job._id}`);
  };

  return (
    <div className="mg-job-container">
      <div className="mg-job-first-section mg-job-first-section-all">
        <h1>Your Jobs & Internships 👋 </h1>
        <p>
          Get the best talent with trending technologies. #1 platform where
          students can find what they want.
        </p>
      </div>

      <div className="mg-jobs-listings">
        {jobs.length === 0 ? (
          <p>No listings found!</p>
        ) : (
          jobs.map((job) => (
            <div className="mg-jobs-single-listings" key={job.id}>
              <div className="mg-jobs-company-details">
                <img src={job.jobOrInternshipImage} />
                <h3>{job.jobOrInternshipCompany}</h3>
                <p>{job.jobOrInternshipLocation}</p>
              </div>
              <div className="mg-jobs-job-details">
                <h3>{job.jobOrInternshipTitle}</h3>
                <p>{job.jobOrInternshipDescription}</p>
              </div>
              <div className="live-and-edit">
                <p id="live">Live</p>
                <p id="live">{job.jobOrInternshipExperience}</p>
                <p id="edit" onClick={() => editJob(job)}>
                  Edit
                </p>
                <p id="delete" onClick={() => deleteJob(job)}>
                  Delete
                </p>{" "}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyCourse;
