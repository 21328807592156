import React, { useEffect, useState } from 'react';
import { LuUsers } from "react-icons/lu";
import { FaRupeeSign } from "react-icons/fa";
import { PiChalkboardTeacher } from "react-icons/pi";
import { IoDocumentText } from "react-icons/io5";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { LuUserPlus } from "react-icons/lu";
import { FaUserClock } from "react-icons/fa";
import { MdOutlineQrCode } from "react-icons/md";

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LineChart, Line,
  PieChart, Pie, Cell,
} from 'recharts';
import axios from 'axios';

const HomeGraphs = () => {
  const [reportData, setReportData] = useState(null);

  const handleDownload = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.multigrad.in/auth/v3/reports-download',
        headers: {
          'authorization': localStorage.getItem('userToken'),
          'Content-Type': 'application/json',
        },
      };
  
      const response = await axios.request(config);
  
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
  
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = [];

        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.multigrad.in/auth/v3/reports',
          headers: {
            'authorization': localStorage.getItem('userToken'),
            'Content-Type': 'application/json'
          },
          data: data
        };

        const response = await axios.request(config);
        setReportData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!reportData) {
    return <div>Loading...</div>;
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
  };

  const { totalStudents, totalFaculty, totalAdmins,totalAlumni, totalRevenue, totalYearlyPaidFees, totalCourse, totalYearlyPendingFees, showAdmFormFalse, studentsToday, weeklyReports } = reportData;

  const pieChartData = [
    { name: 'Total Revenue', value: totalRevenue },
    { name: 'Total Paid', value: totalYearlyPaidFees },
    { name: 'Total Due', value: totalYearlyPendingFees },
  ];

  const barChartData = weeklyReports.map(week => ({ name: `${week.start} - ${week.end}`, students: week.count }));
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className='c100px'>
      <div className='graphs'>
        <div className='totalStudents'>
          <div className='icon'><LuUsers /></div>
          <div className='icon-name'>
            <p>{totalStudents}</p>
            <span>Total Students</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><LuUserPlus /></div>
          <div className='icon-name'>
            <p>{studentsToday}</p>
            <span>Students Joined Today</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><FaUserClock /></div>
          <div className='icon-name'>
            <p id='red'>{showAdmFormFalse}</p>
            <span>Admission Pending</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><PiChalkboardTeacher /></div>
          <div className='icon-name'>
            <p>{totalFaculty}</p>
            <span>Total Faculty</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><IoDocumentText /></div>
          <div className='icon-name'>
            <p>{totalCourse}</p>
            <span>Total Courses</span>
          </div>
        </div>
      </div>

      <div className='graphs'>
        <div className='totalStudents'>
          <div className='icon'><MdOutlineAdminPanelSettings /></div>
          <div className='icon-name'>
            <p>{totalAdmins || 0}</p>
            <span>Total Admins</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><FaRupeeSign /></div>
          <div className='icon-name'>
            <p id="red">{formatCurrency(totalYearlyPendingFees || 0)}</p>
            <span>Total Due Fees</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><FaRupeeSign /></div>
          <div className='icon-name'>
            <p>{formatCurrency(totalYearlyPaidFees)}</p>
            <span>Total Paid Fees</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><FaRupeeSign /></div>
          <div className='icon-name'>
            <p>{formatCurrency(totalRevenue || 0)}</p>
            <span>Total Revenue</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'><FaCloudDownloadAlt /></div>
          <div className='icon-name'>
            <p><button onClick={handleDownload}>Download</button></p>
            <span>Download Reports</span>
          </div>
        </div>

        <div className='totalStudents'>
          <div className='icon'><LuUsers /></div>
          <div className='icon-name'>
            <p>{totalAlumni || 0}</p>
            <span>Total Alumni</span>
          </div>
          </div>

          <div className='totalStudents'>
          <div className='icon'><MdOutlineQrCode /></div>
          <div className='icon-name'>
            <p>{process.env.REACT_APP_CAMPUS_CODE || 0}</p>
            <span>Campus Code</span>
          </div>
          </div>
   
      </div>

    

      <div className='charts'>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={barChartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="students" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={weeklyReports} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="start" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={pieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    
    </div>
  );
};

export default HomeGraphs;
