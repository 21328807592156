import React from 'react';
import '../assets/css/NewDashboard.css'
const NotFound = () => {
  return (
   <div className='container'>
     <h1>Page not found</h1>
     <a href="/">Go Back</a>
   </div>
  );
};

export default NotFound;
