import React from "react";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MessagesPage from "./MessagesPage";
import InputPage from "./InputPage";
import axios from "axios";
import MessagesCom from "./MessagesCom";

const MessengerPage = ({ friend }) => {
  const userToken = localStorage.getItem("userToken");
  const [details, setDetails] = useState("");
  const navigate = useNavigate();
  const [openChat, setOpenChat] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [receiver, setReceiver] = useState(null);
  const [communityData, setCommunityData] = useState([]);
  const [viewChats, setViewChats] = useState(true);
  if (friend) {
    setReceiver(friend);
  }

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/auth/chat/profiles`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
        setUsersData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchChatData();
  }, [usersData, receiver]);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/auth/community`,
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
        setCommunityData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchChatData();
  }, []);

  const handleOpenChat = (r) => {
    setOpenChat(!openChat);
    setReceiver(r);
  };

  useEffect(() => {
    userDetails();
    if (userToken === "" || userToken === null) {
      navigate("/");
    }
  }, []);

  const userDetails = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/user",
      headers: {
        authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDetails(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [msgToggle, setMsgToggle] = useState(true);
  const handleToggle = () => {
    setMsgToggle(!msgToggle);
  };

  return (
    <div className="msg-page-wrapper container">
      <div className="col-4">
        <div className="messenger-page-toggler">
          <div className="switchCommunity">
            <button
              onClick={() => setViewChats(true)}
              id={viewChats ? "activeClass" : ""}
            >
              Chats
            </button>
            <button
              onClick={() => setViewChats(false)}
              id={!viewChats ? "activeClass" : ""}
            >
              Communities
            </button>
          </div>
        </div>
        <div className=" messenger-page-section-1">
          {viewChats
            ? usersData.map((u) => (
                <div
                  key={u.receiverId}
                  className="chats"
                  onClick={() => {
                    handleOpenChat();
                    setReceiver(u);
                  }}
                >
                  <div className="userchat">
                    <img src={u.userProfile} alt="" />
                    <div className="userchatinfo pl-5">
                      <h5>{u.fullName}</h5>
                      <p>{u.lastMessage.slice(0, 30)}</p>
                    </div>
                  </div>
                </div>
              ))
            : communityData.map((u) => (
                <div
                  key={u.receiverId}
                  className="chats"
                  onClick={() => {
                    handleOpenChat();
                    setReceiver(u);
                  }}
                >
                  <div className="userchat">
                    <img src={u.communityBanner} alt="" />
                    <div className="userchatinfo pl-5">
                      <h5>{u.channelName}</h5>
                      <p>{u.description.slice(0, 30)}</p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>

      <div className="col-8 messenger-page-section-2">
        {receiver ? (
          <div className="messenger-page-chats ">
            <div className="chat-info">
              <h5>
                <img src={receiver?.userProfile || receiver?.communityBanner} />{" "}
                {receiver?.fullName || receiver?.channelName}
              </h5>
            </div>
            {viewChats ? (
              <MessagesPage
                senderId={details._id}
                receiverId={receiver?.receiverId || receiver._id}
                userToken={userToken}
                community={!viewChats}
              />
            ) : (
              <MessagesCom groupId={receiver._id} userToken={userToken} />
            )}
            <InputPage
              senderId={details._id}
              receiverId={receiver?.receiverId || receiver._id}
              userToken={userToken}
              community={!viewChats}
            />
          </div>
        ) : (
          <div className="emptyInbox">
            <h2>Welcome to your inbox!</h2>
            <p>
              Choose from your existing conversations, start a new one, or just
              keep swimming.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessengerPage;
