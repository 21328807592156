import React, { useState,useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";

const SearchForm = ({ onSearch }) => {
  const [filters, setFilters] = useState({
    semester: "",
    course: "",
    department: "",
    university:"",
    session: "",
    branch: "",
  });

  const [dropData, setDropData] = useState({});

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/secure/dropdown?campusCode=${process.env.REACT_APP_CAMPUS_CODE}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setDropData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);


  

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    if (e) e.preventDefault();
     onSearch(filters);
  };

  useEffect(() => {
      handleSubmit()  
  }, [])
  


  return (
    <Form onSubmit={handleSubmit} className="space">
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="semester">
            <Form.Label>Semester</Form.Label>
            <Form.Control
              as="select"
              name="semester"
              value={filters.semester}
              onChange={handleChange}
            >
              <option value="">Select Semester</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              {/* Add more semesters as needed */}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="course">
            <Form.Label>Course</Form.Label>
            <Form.Control
              as="select"
              name="course"
              value={filters.course}
              onChange={handleChange}
            >
           
           <option value="">Select Course</option>
             
                {dropData?.courses?.map((s)=>(
              <option value={s.name}>{s.name}</option>
            ))
          }
        
              {/* Add more courses as needed */}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group controlId="branch">
            <Form.Label>University</Form.Label>
            <Form.Control
              as="select"
              name="university"
              value={filters.university}
              onChange={handleChange}
            >
              <option value="">Select University</option>
            
              {dropData?.universities?.map((s)=>(
              <option value={s.name}>{s.name}</option>
            ))
          }
              
              </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="session">
            <Form.Label>Session</Form.Label>
            <Form.Control
              as="select"
              name="session"
              value={filters.session}
              onChange={handleChange}
            >
              <option value="">Select Session</option>
              {dropData?.sessions?.map((s)=>(
              <option value={s.session}>{s.session}</option>
            ))
          }
        
               </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" type="submit">
        Search
      </Button>
    </Form>
  );
};

export default SearchForm;
