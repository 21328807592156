import React from 'react';
import SearchStudent from '../../../screens/cms/admin/SearchStudent';
const SearchStudentPage = () => {
  return (
    <div>
    

    <div className='graphs'>
      <SearchStudent />
    </div>

    </div>
  );
};

export default SearchStudentPage;
