import React from 'react';
import Setting from '../../../screens/profile/settings';

const Settings = () => {
  return (
    <div>
    <div className='graphs'>
      <Setting />
    </div>

    </div>
  );
};

export default Settings;
