import React from 'react';
import SubjectForm from '../../../screens/cms/admin/SubjectForm';


const ManageSubjectNew = () => {
  return (
    <div>
    <div className='graphs'>
      <SubjectForm />
    </div>

    </div>
  );
};

export default ManageSubjectNew;
