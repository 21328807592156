import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";

function NewAccount() {
  const [formData, setFormData] = useState({});
  const [dropData, setDropData] = useState({});
  const [otpSent, setOtpSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const userToken = localStorage.getItem("userToken");

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/secure/dropdown?campusCode=${process.env.REACT_APP_CAMPUS_CODE}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        setDropData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
    userToken && (window.location = "/dashboard");
  }, []);

  const handleSendOtp = (event) => {
    let data = JSON.stringify({
      email: formData.email,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/send-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.success === true) {
          setOtpSent(true);
          Swal.fire({
            icon: "success",
            title: "Otp sent Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await Swal.fire({
            icon: "error",
            title: "User not found!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "User not found!",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      });
  };

  const handleVerifyOtp = (event) => {
    let data = JSON.stringify({
      email: formData.email,
      otp: formData.otp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          icon: "success",
          title: "Logged in Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem("userToken", response.data?.userToken);
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Invalid OTP!",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      });
  };

  const handleLogin = (event) => {
    let data = JSON.stringify({
      ...formData,
      campusCode: process.env.REACT_APP_CAMPUS_CODE,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/registercms",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status === true) {
          await Swal.fire({
            icon: "success",
            title: "User Registered Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleSendOtp();
        } else {
          await Swal.fire({
            icon: "error",
            title: "Invalid Credentials",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Already Registered! Please use different email",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      });
  };
  return (
    <Container className="signup">
      <div className="signup-page" style={{ marginTop: "125px" }}>
        <h1>New Enquiry Form</h1>
        <p>Please enter your details</p>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <label>Full Name</label>
          <Form.Control
            type="text"
            placeholder="Your full name"
            autoFocus
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please fill your full name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <label>Email Address</label>
          <Form.Control
            type="email"
            placeholder="Your email address"
            autoFocus
            id="username"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please fill the username.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <label>Mobile</label>
          <Form.Control
            type="number"
            placeholder="Your Mobile"
            autoFocus
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            autoComplete="off"
            maxLength={10}
            pattern="\d{10}"  
            required
          />
          <Form.Control.Feedback type="invalid">
            Please fill your Mobile.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
          <label>Session</label>
          <Form.Select
            id="session"
            name="session"
            value={formData.session}
            onChange={handleChange}
            required
          >
            <option value="">Select a session</option>
            {dropData?.sessions?.map((s) => (
              <option value={s.session}>{s.session}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please fill the session.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect3">
          <label>Course</label>
          <Form.Select
            id="course"
            name="course"
            value={formData.course}
            onChange={handleChange}
            required
          >
            <option value="">Select Course</option>
            <option value="Medical-Lab-Technology">
              Medical Lab Technology
            </option>

            {dropData?.courses?.map((s) => (
              <option value={s.name}>
                {s.name}- Rs{s.fees}
              </option>
            ))}

            {/* Add more options as needed */}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please fill the course.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="branch">
          <Form.Label>University</Form.Label>
          <Form.Control
            as="select"
            name="university"
            value={formData.university}
            onChange={handleChange}
          >
            <option value="">Select University</option>

            {dropData?.universities?.map((s) => (
              <option value={s.name}>{s.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect2">
          <label>Semester/Class</label>
          <Form.Select
            id="semester"
            name="semester"
            value={formData.semester}
            onChange={handleChange}
            required
          >
            <option value="">Select your semester/class</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please fill the semester.
          </Form.Control.Feedback>
        </Form.Group>

        <button
          variant="danger"
          type="submit"
          className="col-12 customPopupBtn"
          onClick={handleLogin}
        >
          Register
        </button>

        {otpSent && (
          <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>OTP</label>
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                autoFocus
                name="otp"
                value={formData.otp}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please fill the username.
              </Form.Control.Feedback>
            </Form.Group>

            <button
              variant="danger"
              type="submit"
              className="col-12 customPopupBtn"
              onClick={handleVerifyOtp}
            >
              Verify OTP
            </button>
          </div>
        )}
      </div>
    </Container>
  );
}

export default NewAccount;
