import React from 'react';
import MessagePage from '../../../screens/messenger/MessengerPage';
const Message = () => {
  return (
    <div>
    <div className='graphs'>
      <MessagePage />
    </div>

    </div>
  );
};

export default Message;
