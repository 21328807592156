import React, { useState, useEffect,useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import axios from "axios";
const LogoBar = () => {
  const [details, setDetails] = useState("");
    const userToken = localStorage.getItem("userToken");

  
    const userDetails = useCallback(() => {
      let data = "";
  
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.multigrad.in/auth/user",
        headers: {
          authorization: userToken,
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      axios
        .request(config)
        .then((response) => {
          setDetails(response.data.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [userToken]);
  
    useEffect(() => {
      userDetails();
    }, [userDetails]);

  return (
  
          <NavLink to="/dashboard" activeClassName="active-menu-link">
             <img src={details?.userProfile || 'https://www.multigrad.in/static/media/logo_white.065fa1fcc71840853e97baba85e7855f.svg'} className='custom-logo' alt="multigrad-custom" />
          </NavLink>
     
  );
};

export default LogoBar;
